<template>
  <bc-popover v-model="menu" placement="bottom-end" class="offset">
    <template v-slot:activator="{ on, attrs }">
      <bc-btn
        icon
        class="filter-icon"
        v-bind="attrs"
        v-on="on"
        :max-width="30"
        :max-height="30"
      >
        <bc-icon color="#959db5">fas fa-cog</bc-icon>
      </bc-btn>
    </template>

    <template v-slot:header> {{ $t('application.misc.settings') }} </template>

    <div class="card-container">
      <div class="content">
        <div class="flex space"></div>
      </div>

      <bc-divider></bc-divider>

      <bc-spacer></bc-spacer>
      <div>
        <bc-btn block color="link-gray" @click="cancel">
          {{ $t('application.actions.cancel') }}
        </bc-btn>
      </div>
    </div>
  </bc-popover>
</template>

<script>
export default {
  data() {
    return {
      menu: false
    }
  },
  methods: {
    cancel() {
      this.menu = false
    }
  }
}
</script>

<style scoped lang="scss">
.offset {
  margin-left: 5px;
}
.card-container {
  margin: 0 -20px -20px;
}
</style>
