import { api } from '@cargill/shared'
import moment from 'moment'

const basePath = '/api/controltower/capacityUtilization'
const service = {}

service.calculate = async (
  startDate,
  endDate,
  harvestYear = null,
  fiscalYear = null
) => {
  const queryObj = {
    startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
    endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
    harvestYear: harvestYear ? harvestYear.value : '',
    fiscalYear: fiscalYear ? fiscalYear.value : ''
  }
  const query = Object.keys(queryObj)
    .map((key) => `${key}=${queryObj[key]}`)
    .join('&')
  return (await api.get(`${basePath}/calculate?${query}`))?.data
}

export default service
