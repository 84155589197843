<template>
  <bc-popover v-model="menu" placement="bottom-end" class="offset">
    <template v-slot:activator="{ on, attrs }">
      <bc-badge
        :content="notificationContent"
        :value="notificationCount"
        color="red"
        overlap
      >
        <bc-btn
          icon
          class="filter-icon"
          v-bind="attrs"
          v-on="on"
          :max-width="30"
          :max-height="30"
        >
          <bc-icon color="#959db5">fas fa-bell</bc-icon>
        </bc-btn>
      </bc-badge>
    </template>

    <template v-slot:header>
      {{ $t('application.misc.notifications') }}
    </template>

    <div class="card-container">
      <div class="content" v-if="!notificationHasEmptyData">
        <div v-for="(notification, index) in notifications" :key="index">
          <div v-if="index < maxNotificationsSize">
            <notification-box
              :notification="notification"
              @mark-as-read="markAsRead"
            ></notification-box>
            <bc-divider></bc-divider>
          </div>
        </div>
      </div>

      <div class="h-100" v-if="notificationHasEmptyData">
        <bc-card class="empty-data-info-container">
          <bc-message
            type="info"
            icon="fa-info-square"
            :title="$t('application.misc.emptyNotifications')"
            :actions="[]"
          >
          </bc-message>
        </bc-card>
      </div>

      <bc-divider></bc-divider>

      <bc-spacer></bc-spacer>
      <div>
        <bc-btn block color="link-gray" @click="goToAllNotifications">
          {{ $t('application.misc.allNotifications') }}
        </bc-btn>
      </div>
    </div>
  </bc-popover>
</template>

<script>
import {
  authService,
  NotificationBox,
  useModuleOpen,
  eventHub,
  createNotificationService
} from '@cargill/shared'
import _ from 'lodash'

const { getModuleOpen } = useModuleOpen()

export default {
  data() {
    return {
      userRealName: null,
      menu: false,
      updates: 0,
      notifications: [],
      service: null,
      notificationHub: null,
      maxNotificationsSize: 3,
      notificationCount: 0
    }
  },
  components: {
    NotificationBox
  },
  async beforeMount() {
    this.service = createNotificationService()
    await this.getUnreadNotifications()
  },
  async mounted() {
    this.userRealName = authService.getUserRealName()
    switch (getModuleOpen()) {
      case 'integratedPlanning':
        this.$notification.on(
          `${this.getCurrentModule()}Notification`,
          this.notificationUpdate
        )
        this.notificationHub = this.$notification
        break
      case 'controlTower':
        this.$controlTowerNotification.on(
          `${this.getCurrentModule()}Notification`,
          this.notificationUpdate
        )
        this.notificationHub = this.$controlTowerNotification
        break
      default:
        this.notificationHub = null
    }
  },
  async created() {
    this.$root.$on('onNotificationMarkAsReadInGrid', () => {
      this.getUnreadNotifications()
    })
    eventHub.$on('update-user-language', () => {
      this.getUnreadNotifications()
      this.$root.$emit('onNotificationUpdate')
    })
  },
  async beforeDestroy() {
    eventHub.$off('update-user-language')
    if (this.notificationHub !== null) {
      this.notificationHub.off(
        `${this.getCurrentModule()}Notification`,
        this.notificationUpdate
      )
    }
  },
  computed: {
    notificationContent() {
      return this.notificationCount > this.maxNotificationsSize
        ? `${this.maxNotificationsSize}+`
        : this.notificationCount
    },
    notificationHasEmptyData() {
      return Array.isArray(this.notifications) && !this.notifications.length
    },
    currentUser() {
      return this.$store.getters.currentUser
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  methods: {
    async getUnreadNotifications() {
      const result = await this.service.getUnreadNotifications(
        this.maxNotificationsSize
      )
      this.notificationCount = result.size
      this.notifications = result.notifications
    },
    notifySucess() {
      this.notify.success({ title: this.$t('application.misc.success') })
    },
    async markAsRead(notification) {
      await this.service.markAsRead([notification.id])
      this.notifySucess()
      await this.getUnreadNotifications()
      this.$root.$emit('onNotificationUpdate')
    },
    getImageSrc(avatar) {
      if (avatar == null) return ''
      const mime = avatar.mime
      const data = avatar.data
      return `data:${mime};base64,${data}`
    },
    goToAllNotifications() {
      this.$router.push('/notification')
    },
    getCurrentModule() {
      return _.upperFirst(getModuleOpen())
    },
    async notificationUpdate(_newNotifications) {
      const _realNewNotifications = await Promise.all(
        _newNotifications.map((x) => this.service.getById(x.id))
      )
      var newNotifications = [...this.notifications, ..._realNewNotifications]
      newNotifications = _.orderBy(
        newNotifications,
        [(notification) => notification.notificationDate],
        ['desc']
      )
      this.notifications = newNotifications
      this.notificationCount += _realNewNotifications.length
      this.updates++
      this.$root.$emit('onNotificationUpdate')
    }
  }
}
</script>

<style scoped lang="scss">
.offset {
  margin-left: 5px;
}
.card-container {
  margin: 0 -20px -20px;
  min-width: 690.44px;
  overflow-y: auto;
  max-height: 60vh;
}
.content {
  .flex {
    display: flex;
    align-items: center;
  }

  .space {
    padding: 15px 20px;
  }

  .profile {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    background: white;
    border-radius: 50%;
  }

  .name {
    text-transform: capitalize;
    color: var(--bc-primary);
    font-weight: bold;
    margin-right: 0.5rem;
  }
  .name-wrapper {
    margin-left: 1rem;
  }
}
.read-all {
  display: flex;
  width: 100%;
  justify-content: center;
}
</style>
