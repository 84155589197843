<template>
  <bc-popover
    v-model="menu"
    left
    :close-on-content-click="false"
    offset-y
    body-class="no-padding"
    max-width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <bc-btn
        text
        v-bind="attrs"
        v-on="on"
        id="btn-activator"
        @click="toggleColor"
        class="no-padding"
      >
        <div class="btn-content">
          <span class="scenario-name" :style="scenarioName">{{
            currentScenario.name
          }}</span>
          <bc-icon :color="scenarioOpenColor">fas fa-layer-group</bc-icon>
        </div>
      </bc-btn>
    </template>

    <template v-slot:header>
      {{ $t('application.pages.scenarioCargill.scenarios') }}
    </template>

    <div class="card-container">
      <div class="list-wrapper">
        <div class="scenario-label-container">
          <span
            >{{ $t('application.pages.scenarioCargill.selectedScenario') }}:
            {{ currentScenario.name }} </span
          ><i
            class="fas fa-lock-alt lockers"
            v-if="currentScenario.published"
          ></i>
          <i class="fas fa-lock-open-alt" v-if="!currentScenario.published"></i>
          <i 
           @click=" exportAllData(currentScenario)"
           :title="$t('application.pages.scenarioCargill.exportDateScenario') "
           aria-hidden="true" class="icon-export-all fa fa-download theme--dark" 
           >
          </i>              
          
        </div>
        <div class="close-scenario">
          <bc-btn
            color="link-gray"
            @click="$emit('closeScenario', currentScenario)"
          >
            {{ $t('application.pages.scenarioCargill.closeScenario') }}
          </bc-btn>
        </div>
      </div>
      <div class="list-wrapper">
        <div class="scenario-label-list-container">
          <div class="flex">
            <span
              >{{ $t('application.pages.scenarioCargill.createdByName') }}:
            </span>
            <div class="profile-wrapper">
              <div class="profile">
                <v-avatar class="avatar">
                  <v-icon color="#7e7d7d" class="user-icon">fas fa-user</v-icon>
                </v-avatar>
              </div>
              <div class="name-wrapper">
                <span class="name" v-if="currentScenario.createdById == 1"
                  >Admin</span
                >
                <span class="name" v-if="currentScenario.createdById != 1">{{
                  currentScenario.createdByName
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="update-date" v-show="currentScenario.lastUpdatedDate"
            >{{ $t('application.pages.scenarioCargill.updateDate') }} :
            {{ currentScenario.lastUpdatedDate }}</span
          >
        </div>
      </div>

      <!-- SEPARATOR -->
      <div class="content-label">
        <div class="flex">
          <bc-switch
            class="offset"
            v-model="allScenarios"
            @change="toggleAllScenarios"
            :label="$t('application.pages.scenarioCargill.showAllScenarios')"
          ></bc-switch>
        </div>
      </div>

      <!-- SCENARIOS LIST-->
      <div
        v-for="(scenario, index) in scenarios"
        :key="index"
        class="scenarios-list"
      >
        <bc-divider></bc-divider>
        <div class="list-wrapper">
          <div class="scenario-label-container">
            <span class="scenario-name">
              {{ $t('application.pages.scenarioCargill.title') }}
              {{ scenario.name }}
            </span>
          </div>
          <div class="action-icons">
            <i class="fas fa-folder" @click="openScenario(scenario)"></i
            ><i class="fas fa-lock-alt lockers" v-if="scenario.published"></i>
            <i class="fas fa-lock-open-alt" v-if="!scenario.published"></i>
          </div>
        </div>
        <div class="list-wrapper">
          <div class="scenario-label-list-container">
            <div class="flex">
              <span
                >{{ $t('application.pages.scenarioCargill.createdByName') }}:
              </span>
              <div class="profile-wrapper">
                <div class="profile">
                  <bc-avatar class="avatar">
                    <bc-icon color="#7e7d7d" class="user-icon"
                      >fas fa-user</bc-icon
                    >
                  </bc-avatar>
                </div>
                <div class="name-wrapper">
                  <span class="name">{{ scenario.createdByName }}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <span class="update-date" v-if="scenario.lastUpdatedDate"
              >{{ $t('application.pages.scenarioCargill.updateDate') }} :
              {{ scenario.lastUpdatedDate }}</span
            >
          </div>
        </div>
      </div>

      <bc-divider></bc-divider>
      <div class="card-actions">
        <bc-btn block @click="showAllScenariosAction" color="link-gray">
          {{ $t('application.pages.scenarioCargill.viewAllScenarios') }}
        </bc-btn>
      </div>
    </div>
  </bc-popover>
</template>

<script>
export default {
  name: 'ScenarioList',
  props: {
    scenarios: {
      type: Array,
      default: () => []
    },
    currentScenario: {
      type: Object,
      default: null
    }
  },
  watch: {
    menu(_val) {
      if (_val) {
        this.$emit('onMenuToggle', _val)
      }
    }
  },
  data() {
    return {
      scenarioOpenColor: '#959db5',
      menu: false,
      scenarioName: {
        color: '#959db5'
      },
      allScenarios: false
    }
  },
  methods: {
    toggleColor() {
      this.menu
        ? (this.scenarioOpenColor = '#959db5')
        : (this.scenarioOpenColor = '#24BBEF')

      this.scenarioName.color = this.scenarioOpenColor
    },
    openScenario(_scenarioToOpen) {
      this.$emit('onOpenScenario', this.currentScenario, _scenarioToOpen)
      this.toggleMenu()
    },
    exportAllData(_currentScenario) {
      this.$emit('exportDataScenario', _currentScenario)
      this.toggleMenu()
    },
    toggleAllScenarios() {
      this.$emit('toggleAll', this.allScenarios)
    },
    showAllScenariosAction(_event) {
      this.$emit('showAllScenariosAction', _event)
      this.toggleMenu()
    },
    toggleMenu() {
      this.menu = !this.menu
    }
  }
}
</script>

<style scoped lang="scss">
.list-content {
  padding: 0;
}
.no-padding {
  padding: 0;
}
.list-item {
  padding: 0 !important; //override vuetify
  margin: 0 !important; //override vuetify
}
.close-scenario {
  cursor: pointer;
}
.scenario-icon {
  color: #959db5;
}
.btn-content {
  padding: 7px 14px;
  background-color: #2b323f;
  border-radius: 5px;
  .scenario-name {
    font-size: 14px;
    padding: 0 0.8rem;
    color: #959db5;
  }
}
.v-btn:before {
  background: transparent;
}

.card-container {
  margin: 0 -20px -20px;
  min-width: 690.44px;
}

.list-wrapper {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 43px;
  .action-icons {
    i {
      margin-left: 0.3rem;
      padding: 0.5rem;
      color: #959db5;
      cursor: pointer;
    }
  }
  .scenario-label-container {
    display: flex;
    justify-content: space-between;
    .scenario-name {
      color: #959db5;
    }
    span {
      font-weight: bold;
      color: #24bbef;
      letter-spacing: 0px;
    }
    i {
      margin-left: 0.5rem;
      color: #959db5;
    }
  }
  .scenario-label-list-container {
    display: flex;
    justify-content: space-between;
    color: #959db5;
    .flex {
      display: flex;
      align-items: center;
    }
    .profile-wrapper {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      .profile {
        .avatar {
          width: 2rem !important;
          height: 2rem !important;
          min-width: 2rem !important;
        }
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        background: white;
        border-radius: 50%;
        .user-icon {
          font-size: 1rem;
        }
      }
    }
    .name-wrapper {
      margin-left: 0.5rem;
      .name {
        font-weight: normal;
      }
    }
    span {
      font-weight: bold;
      color: #959db5;
      letter-spacing: 0px;
    }
    .lockers {
      margin-left: 0.5rem;
      color: #959db5;
    }
  }
  .update-date {
    color: #959db5;
    font-weight: bold;
  }
}

:root .v-menu__content .v-list {
  background: #262c39;
}
:root .v-input:not(.v-select--is-multi) {
  height: inherit;
}

.content-label {
  color: #6f7283;
  margin-top: 0;
  text-transform: uppercase;
  display: flex;
  padding: 0 1rem;
  background-color: #2b323f;
  align-items: center;
  .flex {
    display: flex;
    align-items: center;
    i {
      margin-left: 0.5rem;
    }
  }
}
.card-actions {
  display: flex;
  justify-content: center;
}
#btn-activator {
  padding: 0 !important; //overrides v-btn 0 40px!important
  margin-right: 20px;
}
.icon-export-all{
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    i {
      font-size: 16px;
      color: rgb(149, 157, 181);
      caret-color: rgb(149, 157, 181);
    }
}
</style>
