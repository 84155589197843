<template>
  <div class="alerts-wrapper">
    <bc-alert
      :close-label="$t('application.actions.close')"
      :type="alert.type"
      v-for="alert in alerts"
      :key="alert.id"
      dark
      dismissible
      @input="$emit('onClose', alert)"
    >
      <p class="uppercase">
        {{ alert.message }}
      </p>
    </bc-alert>
  </div>
</template>
<script>
import { BcAlert } from '@brain/core'

export default {
  name: 'AlertNotification',
  components: {
    BcAlert
  },
  props: {
    alerts: {
      type: Array
    }
  },
  data() {
    return {}
  }
}
</script>
<style scoped lang="scss">
.alerts-wrapper {
  padding: 0 15px;
}
.uppercase {
  text-transform: uppercase;
}
</style>
