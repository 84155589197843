<template>
  <div class="controls-bar">
    <slot></slot>
    <notifications
      :key="this.moduleChanged"
      v-if="
        this.module !== null &&
        (this.module == 'controlTower' || this.module == 'integratedPlanning')
      "
    />
    <user-action />
    <config-action />
  </div>
</template>

<script>
import Notifications from './notifications'
import UserAction from './user-action'
import ConfigAction from './config-action'

export default {
  components: { Notifications, UserAction, ConfigAction },
  props: {
    module: null
  },
  data() {
    return {
      moduleChanged: 0
    }
  },
  watch: {
    module() {
      this.moduleChanged++
    }
  }
}
</script>

<style scoped>
.controls-bar {
  width: 100%;
  display: contents;
}
</style>
