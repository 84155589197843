<template>
  <v-card class="consolidated-container" color="#252C39">
    <v-toolbar color="#2B323F">
      <v-toolbar-title class="default-title-layout">
        {{ $t('controlTower.pages.forecast.consolidated') }}
      </v-toolbar-title>
    </v-toolbar>
    <div class="consolidated-wrapper">
      <div class="cover-area">
        <div class="inner-text">
          {{ percent }}<span class="percent">%</span>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ForecastConsolidated',
  props: {
    percent: {
      type: Number
    }
  }
}
</script>

<style lang="scss" scoped>
.consolidated-wrapper {
  width: 100%;
  height: calc(100% - 64px);
}
.consolidated-container {
  width: 100%;
  max-width: 517px;
}
.indicador-list-item {
  flex: 1;
  padding: 15px;
}

.default-title-layout {
  text-align: left;
  font: normal normal bold 14px/63px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}
.consolidated-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .cover-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 64px);
    background: #2b323f;
    margin: 2rem 2rem;
  }
  .inner-text {
    font-size: 4.375rem;
    color: #24bbef;
    font-weight: bold;
    .percent {
      font-size: 1.875rem;
      position: relative;
      top: 1rem;
    }
  }
}
</style>
