export default {
  optimal: 'optimal',
  finished: 'finished',
  error: 'error',
  creatingInput: 'creatingInput',
  queued: 'queued',
  pending: 'pending',
  readingSolution: 'readingSolution',
  writingResults: 'writingResults',
  userInterrupted: 'userInterrupted',
  requestingOptimizationStop: 'requestingOptimizationStop',
  errorWritingInput: 'errorWritingInput',
  errorAutomaticCapacity: 'errorAutomaticCapacity',
  inputCreationQueued: 'inputCreationQueued',
  waitingForLogs: 'waitingForLogs',
  logReading: 'logReading',
  writingLogs: 'writingLogs'
}
