<template>
  <bc-app-bar app dense flat color="primary-gray" class="bc-header">
    <div class="d-flex align-center">
      <div class="row">
        <bc-drawer
          v-if="selectedModule"
          :items="menuItems ? menuItems : filteredMenuItems"
        >
        </bc-drawer>

        <div class="logo-header bc-ml-20 brain-logo">
          <slot name="logo-brain">
            <img
              src="../../assets/images/logo.svg"
              @click="home"
              class="logo"
            />
          </slot>
        </div>
      </div>
    </div>

    <bc-spacer></bc-spacer>
    <controls-bar-menu :module="selectedModule.module">
      <scenario-list
        v-if="scenarioOpen && isScenarioSelectorVisible"
        :currentScenario="scenarioOpen"
        :scenarios="scenarios"
        @toggleAll="getLastCreatedScenarios($event)"
        @closeScenario="closeScenario"
        @exportDataScenario="exportDataScenario"
        @onMenuToggle="updateList"
        @onOpenScenario="openScenario"
        @showAllScenariosAction="showAllScenariosAction"
      ></scenario-list>

      <ongoing-tasks-list
        v-if="
          selectedModule.module !== null &&
          selectedModule.module == 'integratedPlanning'
        "
      ></ongoing-tasks-list>

      <bc-popover-icon icon="fa-th" :closeButton="true">
        <template v-slot:header>{{
          $t('application.misc.selectAModule')
        }}</template>
        <bc-box-selector
          @change="boxSelectorChange"
          boxColumns="3"
          :value="selectedModule"
          :options="boxSelectorData"
        ></bc-box-selector>
      </bc-popover-icon>

      <div class="selected-module" v-if="selectedModule">
        <bc-icon class="user-icon">fas {{ selectedModule.icon }}</bc-icon>
        <span>{{ selectedModule.name }}</span>
      </div>

      <div class="separator"></div>
    </controls-bar-menu>
  </bc-app-bar>
</template>
<script>
import { computed } from '@vue/composition-api'
import {
  BcSpacer,
  BcAppBar,
  BcDrawer,
  BcBoxSelector,
  BcPopoverIcon
} from '@brain/core'
import { eventHub } from '@cargill/shared'
import menu from '../../router/menu'
import {
  useModuleOpen,
  getModules,
  useScenarioOpen,
  scenarioCargillService,
  userSettingsService,
  moduleCargillEnum
} from '@cargill/shared'

import ControlsBarMenu from '../../components/controls-bar-menu/ControlsBarMenu.vue'
import OngoingTasksList from '../../components/ongoing-tasks-list/OngoingTasksList'
import ScenarioList from './ScenarioList.vue'
import moment from 'moment'
import FileDownload from 'js-file-download'
const { getScenarioOpen, updateScenarioOpen } = useScenarioOpen()

const { getModuleOpen, getMenuItems, updateMenuItems, updateModuleOpen } =
  useModuleOpen()

let scenarioOpen = null

export default {
  name: 'CargillHeader',
  props: {
    menuItems: null
  },
  components: {
    BcSpacer,
    BcAppBar,
    BcDrawer,
    ControlsBarMenu,
    ScenarioList,
    BcBoxSelector,
    BcPopoverIcon,
    OngoingTasksList
  },
  data() {
    return {
      showConfirmOpen: true,
      scenarios: [],
      userSettings: {}
    }
  },
  setup(props, ctx) {
    scenarioOpen = computed(() => {
      let scenario = getScenarioOpen()
      if (scenario?.id == null) {
        scenarioCargillService.getMasterData().then((resp) => {
          scenario = resp
          if (scenario.lastUpdatedDate)
            scenario.lastUpdatedDate = moment(scenario.lastUpdatedDate).format(
              ctx.root.$t('application.dateFormat')
            )
          updateScenarioOpen(scenario)
        })
      }
      return scenario
    })
    const boxSelectorData = computed(() => {
      return getModules().map((module) => {
        return {
          ...module,
          name: ctx.root.$t(module.name)
        }
      })
    })

    const boxSelectorChange = (newBoxSelector) => {
      updateModuleOpen(newBoxSelector.module, menu, ctx.root)
    }

    updateMenuItems(menu, ctx.root)

    return {
      scenarioOpen,
      boxSelectorChange,
      boxSelectorData
    }
  },
  mounted() {
    let vm = this
    this.getLastCreatedScenarios()
    this.getUserSettings()

    eventHub.$on('open-scenario', (_scenario, _showAgain) => {
      updateScenarioOpen(_scenario.newScenario)
      vm.updateUserSettings(_showAgain)
      vm.notifySucess()
    })
    eventHub.$on('update-current-scenario', (_showAgain) => {
      vm.updateUserSettings(_showAgain)
      vm.updateCurrentScenario()
    })
    eventHub.$on('close-scenario', (_scenario, _showAgain) => {
      vm.updateUserSettings(undefined, _showAgain)
      vm._closeScenario()
    })
  },
  beforeDestroy() {
    eventHub.$off('open-scenario')
    eventHub.$off('update-current-scenario')
    eventHub.$off('close-scenario')
    // eventHub.$off('export-data-scenario')
  },
  computed: {
    filteredMenuItems() {
      return getMenuItems()
    },
    selectedModule() {
      return this.boxSelectorData.find((o) => o.module === getModuleOpen())
    },
    isScenarioSelectorVisible() {
      return getModuleOpen() === moduleCargillEnum.integratedPlanning
    }
  },
  methods: {
    async getUserSettings() {
      this.userSettings = await userSettingsService.get()
    },
    async getAllExport() {
      const data = await scenarioCargillService.exportDataScenario()
      if (!this.hasErrors(data)) {
        this.notifySucess()
        FileDownload(data, `Supply Brain Data.zip`)
      } else {
        this.notifyErrors(data)
      }
    },
    home() {
      this.$router.push('/')
    },
    showAllScenariosAction() {
      this.$router.push('/scenario-cargill')
    },
    openScenario(_currentScenario, _scenarioToOpen) {
      if (this.userSettings.scenarioAskAgain)
        eventHub.$emit(
          'confirm-open-scenario',
          _currentScenario,
          _scenarioToOpen
        )
      else this._openScenario(_scenarioToOpen)
    },
    _openScenario(scenario) {
      updateScenarioOpen(scenario)
      this.notifySucess()
    },
    closeScenario(_currentScenario) {
      if (this.userSettings.scenarioCloseAskAgain) {
        eventHub.$emit('confirm-close-scenario', _currentScenario)
      } else {
        this._closeScenario()
      }
    },
    _closeScenario() {
      updateScenarioOpen(null)
      if (this.$router.currentRoute.path !== '/') this.$router.push('/')

      this.notifySucess()
    },
    exportDataScenario(_currentScenario) {
      this._exportDataScenario()
    },
    _exportDataScenario() {
      this.getAllExport()
    },
    hasErrors(response) {
      return response.error
    },
    notifyErrors(error) {
      const errors = error?.error?.response?.data?.errors ?? []
      errors.forEach((error) => {
        this.notify.error({
          title: this.$t(error)
        })
      })
    },
    notifySucess() {
      this.notify.success({ title: this.$t('application.misc.success') })
    },
    async updateUserSettings(_showAgain, _closeShowAgain) {
      const userSettings = await userSettingsService.get()
      userSettings.scenarioAskAgain =
        _showAgain != undefined ? !_showAgain : userSettings.scenarioAskAgain
      userSettings.scenarioCloseAskAgain =
        _closeShowAgain != undefined
          ? !_closeShowAgain
          : userSettings.scenarioCloseAskAgain
      userSettingsService.update(userSettings)
    },
    async getLastCreatedScenarios(_showAll) {
      this.scenarios = await scenarioCargillService.getLastCreatedScenarios(
        _showAll
      )
      this.scenarios.forEach((_scenario) => {
        if (_scenario.lastUpdatedDate)
          _scenario.lastUpdatedDate = moment(_scenario.lastUpdatedDate).format(
            this.$t('application.dateFormat')
          )
      })
    },
    updateList() {
      this.getLastCreatedScenarios()
      this.getUserSettings()
    },
    updateCurrentScenario() {
      scenarioOpen = getScenarioOpen()
      scenarioOpen.lastUpdatedDate = moment(
        scenarioOpen.lastUpdatedDate
      ).format(this.$t('application.dateFormat'))
    }
  }
}
</script>
<style lang="scss" scoped>
.separator {
  height: 22px;
  width: 1px;
  background: rgb(149, 157, 181);
  margin: 0 5px 0 20px;
}
.scenario-label {
  padding: 0.8rem 1.2rem;
}
.header {
  background: var(--bc-navbar-bg);
  align-items: center;
  height: 50px;
  display: flex;
  padding-left: 1.5rem;
}
.logo-header {
  width: 196px;
  height: 36px;
  cursor: pointer;
  align-self: center;
  display: flex;
  margin-left: 1em !important;
}
.row {
  margin: 0.5rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
}
.bc-header .icon-container {
  margin-left: 0;
}
.selected-module {
  position: relative;
  margin-left: 20px;
  width: auto;
  color: var(--bc-primary);
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    margin-right: 1rem;
    color: var(--bc-primary);
  }
}
.module-name {
  text-align: left;
  font: normal normal bold 16px/14px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}
.logo {
  width: 196px;
  height: 36px;
  cursor: pointer;
  vertical-align: middle;
}
.brain-logo {
  width: auto;
  z-index: 1001;
}
</style>
