<template>
  <bc-footer app dense flat color="primary-gray" class="bc-footer">
    <div class="d-flex align-items-center">
      <div class="logo-footer">
        <slot name="logo">
          <img src="../../assets/images/logo_accenture.png" />
        </slot>
      </div>
      <div class="separator-footer"></div>
      <span class="description-footer" style="margin-left: 15px">
        {{ name }}
      </span>
    </div>
    <div class="box-link-footer">
      <slot name="center">
        <a href="mailto:support.sb.cargill@accenture.com">{{
          $t('core.footer.support')
        }}</a>
        <div class="separator-footer"></div>
        <a>{{ $t('core.footer.privacyPolicy') }}</a>
        <div class="separator-footer"></div>
        <bc-dialog v-model="dialog" width="800">
          <template v-slot:activator="{ on, attrs }">
            <a v-bind="attrs" v-on="on">{{ $t('core.footer.terms') }}</a>
          </template>

          <bc-card>
            <bc-card-title>
              {{ $t('core.footer.terms') }}
            </bc-card-title>

            <bc-card-text>
              <p>{{ $t('application.terms.restrictions') }}</p>
              <p>{{ $t('application.terms.accentureRights') }}</p>
              <p>{{ $t('application.terms.clientContent') }}</p>
              <p>{{ $t('application.terms.host') }}</p>
            </bc-card-text>

            <v-divider></v-divider>

            <bc-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                {{ $t('application.actions.close') }}
              </v-btn>
            </bc-card-actions>
          </bc-card>
        </bc-dialog>
      </slot>
    </div>

    <div class="copyright-footer">
      <span>{{ $t('core.footer.copyright', [actualYear]) }}</span>
    </div>
  </bc-footer>
</template>
<script>
import moment from 'moment'
import { BcFooter } from '@brain/core'

export default {
  name: 'CargillFooter',
  props: {
    applicationName: { default: null }
  },
  components: {
    BcFooter
  },
  data: () => ({
    actualYear: moment().format('YYYY'),
    name: '',
    dialog: false
  }),
  created() {
    this.name = this.applicationName || process.env.VUE_APP_NAME
  },
  computed: {
    environment: function () {
      return process.env.VUE_APP_ENVIRONMENT !== 'production'
        ? `/ ${process.env.VUE_APP_ENVIRONMENT}`
        : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.bc-footer {
  background: var(--bc-footer-bg);
  height: 50px;
  display: flex;
  justify-items: center;
  justify-content: space-between;
  align-content: center;
  width: 100%;
  padding: 0 0 0 16px;
  font-size: 14px;
  position: relative;

  .bc-footer hr {
    box-sizing: content-box;
    border: 0;
  }
  .align-items-center {
    align-items: center !important;
  }
  .container-fluid {
    height: 54px;
  }

  .d-flex {
    display: flex !important;
    height: 100%;
  }

  .logo-footer {
    width: 104px;
    height: 30px;
    float: left;
    margin-top: -0.7rem;
  }

  .separator-footer {
    height: 22px;
    width: 1px;
    background: #959db5;
  }

  .description-footer {
    font-family: var(--bc-font-regular);
    font-size: var(--bc-font-size--body);
    float: left;
    color: var(--bc-light-grey);
    opacity: 0.7;
    text-transform: uppercase;
  }

  .copyright-footer {
    font-family: var(--bc-font-regular);
    font-size: var(--bc-font-size--body);
    color: var(--bc-light-grey);
    opacity: 0.7;
    margin-right: 9px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding-right: 20px;
  }

  .box-link-footer {
    text-transform: uppercase;
    display: flex;
    align-items: center;

    a {
      font-family: var(--bc-font-regular);
      color: var(--bc-footer-text-title-color);
      font-size: var(--bc-font-size--body);
      margin-left: 16px;
      margin-right: 16px;
      opacity: 1;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
</style>
