<template>
  <bc-popover
    v-model="menu"
    left
    :close-on-content-click="false"
    offset-y
    body-class="no-padding"
    max-width="800"
  >
    <template v-slot:activator="{ on, attrs }">
      <bc-btn
        text
        v-bind="attrs"
        v-on="on"
        id="btn-activator"
        @click="updates = 0"
      >
        <v-badge :content="updates" :value="updates" color="red" dot>
          <bc-icon
            :class="{
              animate: hasOptimization,
              'sync-icon-color': !hasOptimization
            }"
            >fas fa-sync</bc-icon
          >
        </v-badge>
      </bc-btn>
    </template>

    <template v-slot:header
      >{{ $t('application.pages.optimizationQueueEntity.title') }}
    </template>

    <div class="card-container">
      <div v-if="!optimizationHasEmptyData">
        <div
          v-for="(optimization, index) in optimizations"
          :key="index"
          class="optimizations-list"
        >
          <div class="list-wrapper">
            <div class="scenario-label-container">
              <span class="scenario-name-container">
                <div class="optimization-info">
                  <div>
                    <bc-icon color="#959DB5" class="icon-opt"
                      >fas fa-tachometer-alt-fastest</bc-icon
                    >
                    {{
                      $t(
                        'application.pages.optimizationQueueEntity.optimization'
                      )
                    }}
                  </div>
                  <div>
                    {{ solicitedByLabel(optimization) }}
                  </div>
                </div>
                <div class="scenario-name">
                  <div>
                    {{ optimization.scenarioName }}
                  </div>
                  <div v-if="canCancelOptimization(optimization)">
                    <bc-btn
                      color="link-gray"
                      id="btn-cancel"
                      @click="cancelOptimization(optimization)"
                    >
                      {{ $t('application.actions.cancel') }}
                    </bc-btn>
                  </div>
                </div>
                <div class="scenario-status">
                  <div>
                    <div class="status-container">
                      <div
                        class="status-circle"
                        :style="'background-color:' + optimization.statusColor"
                      ></div>
                      {{ statusLabel(optimization) }}
                    </div>
                  </div>
                  <div>{{ updateDateLabel(optimization) }}</div>
                </div>
              </span>
            </div>
          </div>
          <bc-divider></bc-divider>
        </div>
      </div>

      <div class="h-100" v-if="optimizationHasEmptyData">
        <bc-card class="empty-data-info-container">
          <bc-message
            type="info"
            icon="fa-info-square"
            :title="$t('application.misc.emptyOptimizations')"
            :actions="[]"
          >
          </bc-message>
        </bc-card>
      </div>

      <bc-divider></bc-divider>
      <div class="card-actions">
        <bc-btn block @click="showAllOngoingTasks" color="link-gray">
          {{
            $t('application.pages.optimizationQueueEntity.showAllOngoingTasks')
          }}
        </bc-btn>
      </div>
    </div>
  </bc-popover>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
import optimizationQueueService from '../../api/optimizationQueueService'
import optimizationService from '../../api/optimizationService'
import { useUserSettings } from '@cargill/shared'

const { getUserSettings } = useUserSettings()

export default {
  name: 'OngoingTasksList',
  data() {
    return {
      menu: false,
      optimizations: [],
      updates: 0
    }
  },
  async beforeMount() {
    this.optimizations =
      await optimizationQueueService.getActiveOptimizationQueueAsNotification()
  },
  async created() {
    this.$notification.on(
      'OptimizationUpdateNotification',
      this.optimizationUpdate
    )
  },
  async beforeDestroy() {
    this.$notification.off(
      'OptimizationUpdateNotification',
      this.optimizationUpdate
    )
  },
  computed: {
    hasOptimization() {
      return (
        Array.isArray(this.optimizations) &&
        this.optimizations.length &&
        this.optimizations.some(
          (optimization) => optimization.isFinished === false
        )
      )
    },
    optimizationHasEmptyData() {
      return Array.isArray(this.optimizations) && !this.optimizations.length
    }
  },
  methods: {
    canCancelOptimization(optimization) {
      const userSettings = getUserSettings()
      const hasPermissionToStop =
        userSettings?.id == optimization?.requestingUserId
      return (
        optimizationService.canRequestStop(optimization) && hasPermissionToStop
      )
    },
    statusLabel(optimization) {
      return this.$t(
        `application.enums.optimizationStatus.${_.camelCase(
          optimization.status
        )}`
      )
    },
    solicitedByLabel(optimization) {
      return `${this.$t(
        'application.pages.optimizationQueueEntity.requestingUserName'
      )}: ${optimization.requestingUserName}`
    },
    updateDateLabel(optimization) {
      const updateOn = this.$t(
        'application.pages.optimizationQueueEntity.updateOn'
      )
      const dateTimeFormat = this.$t('application.dateTimeFullFormat')
      const formatedDate = moment(optimization.updateDate).format(
        dateTimeFormat
      )
      return `${updateOn}: ${formatedDate}`
    },
    showAllOngoingTasks() {
      this.$router.push('/ongoingTasks')
    },
    cancelOptimization(optimization) {
      optimizationService.stop(optimization.optimizationQueueEntityId)
    },
    optimizationUpdate(_optimizationUpdateNotification) {
      var newOptimizations = [...this.optimizations]
      const index = newOptimizations.findIndex(
        (optimization) =>
          optimization.scenarioId == _optimizationUpdateNotification.scenarioId
      )
      if (index >= 0) {
        newOptimizations[index] = _optimizationUpdateNotification
      } else {
        newOptimizations.unshift(_optimizationUpdateNotification)
      }
      newOptimizations = _.orderBy(
        newOptimizations,
        [(optimization) => optimization.updateDate],
        ['desc']
      )
      this.optimizations = newOptimizations
      this.updates++
    }
  }
}
</script>
<style scoped lang="scss">
/* start scrollbar */

::-webkit-scrollbar {
  width: 3px;
  height: 85px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b9b9b9;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #27bee7;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #27bee7;
}
/* end scrollbar*/
.animate {
  color: var(--bc-primary);
  transform: rotate(0deg);
  animation: rotate 0.75s infinite linear;
}
.v-btn .animate {
  color: var(--bc-primary);
}
.v-btn .sync-icon-color {
  color: #959db5;
}
.sync-icon-color {
  color: #959db5;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.icon-opt {
  font-size: 15px;
  padding-right: 5px;
}
.list-content {
  padding: 0;
}
.no-padding {
  padding: 0;
}
.list-item {
  padding: 0 !important; //override vuetify
  margin: 0 !important; //override vuetify
}
.close-scenario {
  cursor: pointer;
}
.scenario-icon {
  color: #959db5;
}
.btn-content {
  padding: 7px 14px;
  background-color: #2b323f;
  border-radius: 5px;
}
.v-btn:before {
  background: transparent;
}

.card-container {
  margin: 0 -20px -20px;
  min-width: 690.44px;
  overflow-y: auto;
  max-height: 60vh;
}

.list-wrapper {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  align-items: center;
  padding: 1rem;
  .action-icons {
    i {
      margin-left: 0.3rem;
      padding: 0.5rem;
      color: #959db5;
      cursor: pointer;
    }
  }
  .scenario-label-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .scenario-name-container {
      .optimization-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        span {
          font-weight: bold;
          color: #959db5;
          letter-spacing: 0px;
        }
      }

      .scenario-name {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      .scenario-status {
        display: flex;
        justify-content: space-between;
        .status-container {
          display: flex;
        }
        .status-circle {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
      font-size: 14px;
      color: #959db5;
      display: flex;
      flex-direction: column;
      width: 100%;
      grid-gap: 10px;
    }
    span {
      font-weight: bold;
      color: #24bbef;
      letter-spacing: 0px;
    }
    i {
      color: #959db5;
    }
  }
  .scenario-label-list-container {
    display: flex;
    justify-content: space-between;
    color: #959db5;
    .flex {
      display: flex;
      align-items: center;
    }
    .profile-wrapper {
      display: flex;
      align-items: center;
      margin-left: 0.5rem;
      .profile {
        .avatar {
          width: 2rem !important;
          height: 2rem !important;
          min-width: 2rem !important;
        }
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        background: white;
        border-radius: 50%;
        .user-icon {
          font-size: 1rem;
        }
      }
    }
    .name-wrapper {
      margin-left: 0.5rem;
      .name {
        font-weight: normal;
      }
    }
    span {
      font-weight: bold;
      color: #959db5;
      letter-spacing: 0px;
    }
    .lockers {
      margin-left: 0.5rem;
      color: #959db5;
    }
  }
  .update-date {
    color: #959db5;
    font-weight: bold;
  }
}

:root .v-menu__content .v-list {
  background: #262c39;
}
:root .v-input:not(.v-select--is-multi) {
  height: inherit;
}

.content-label {
  color: #6f7283;
  margin-top: 0;
  text-transform: uppercase;
  display: flex;
  padding: 0 1rem;
  background-color: #2b323f;
  align-items: center;
  .flex {
    display: flex;
    align-items: center;
    i {
      margin-left: 0.5rem;
    }
  }
}
.card-actions {
  display: flex;
  justify-content: center;
}
#btn-activator {
  padding: 0 !important; //overrides v-btn 0 40px!
  max-height: 30px;
  max-width: 30px;
  margin: 0 15px;
}
#btn-cancel {
  padding: 0 !important; //overrides v-btn 0 40px!
  height: auto;
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 30px !important;
}
</style>
