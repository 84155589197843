import { api } from '@cargill/shared'
import moment from 'moment'

const basePath = '/api/controltower/saleForecastAccuracy'
const service = {}

service.calculate = async (startDate, endDate) => {
  const queryObj = {
    startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
    endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : ''
  }
  const query = Object.keys(queryObj)
    .map((key) => `${key}=${queryObj[key]}`)
    .join('&')
  return (await api.get(`${basePath}/calculate?${query}`))?.data
}

export default service
