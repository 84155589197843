<template>
  <v-card class="consolidated-container" color="#252C39">
    <v-toolbar color="#2B323F">
      <v-toolbar-title class="default-title-layout">
        {{ $t('controlTower.pages.capacityUtilization.consolidated') }}
      </v-toolbar-title>
    </v-toolbar>
    <div class="consolidated-wrapper">
      <div class="cover-area">
        <div class="inner-title-text">
          <div class="title">
            {{ $t('controlTower.pages.capacityUtilization.harvest') }}
          </div>
          <div>{{ harvestYear }}</div>
        </div>
        <div class="inner-text">
          {{ percentHarvest }}<span class="percent">%</span>
        </div>
      </div>
      <div class="cover-area">
        <div class="inner-title-text">
          <div>
            {{ $t('controlTower.pages.capacityUtilization.fiscalYear') }}
          </div>
          <div>{{ fiscalYear }}</div>
        </div>
        <div class="inner-text">
          {{ percentFiscalYear }}<span class="percent">%</span>
        </div>
      </div>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'HarvestFiscalConsolidated',
  props: {
    percentHarvest: {
      type: Number,
      default: 0
    },
    percentFiscalYear: {
      type: Number,
      default: 0
    },
    harvestYear: {
      type: Number,
      default: 0
    },
    fiscalYear: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.consolidated-wrapper {
  width: 100%;
  height: calc(100% - 64px);
}
.consolidated-container {
  width: 100%;
  max-width: 440px;
  min-height: 455px;
}
.indicador-list-item {
  flex: 1;
  padding: 15px;
}

.default-title-layout {
  text-align: left;
  font: normal normal bold 14px/63px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}
.consolidated-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .cover-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 64px);
    background: #2b323f;
    margin: 1rem 1rem;
    flex-direction: column;
  }
  .inner-text {
    font-size: 4.375rem;
    color: #24bbef;
    font-weight: bold;
    .percent {
      font-size: 1.875rem;
      position: relative;
      top: 1rem;
    }
  }
  .inner-title-text {
    color: #959db5;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
  }
}
</style>
