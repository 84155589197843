<template>
  <v-card class="indicadores-list-card" color="#2B323F">
    <v-toolbar color="#2B323F">
      <v-toolbar-title class="default-text-layout">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <v-btn class="expander" icon>
        <v-icon>fa fa-arrows-alt</v-icon>
      </v-btn> -->
    </v-toolbar>
    <div class="list-wrapper">
      <div class="list-container" v-for="(value, index) in values" :key="index">
        <individual-indicator
          :color="'grey'"
          :value="value"
        ></individual-indicator>
        <hr />
      </div>
    </div>
    <v-footer color="#2B323F" class="footer" v-if="containsEffective">
      <span class="default-text-layout footer-font">
        {{ $t('controlTower.components.chartIndicator.effectiveSubtitle') }}
      </span>
      <span
        class="default-text-layout footer-font"
        style="margin-left: 2rem"
        v-if="containsOfenders"
      >
        {{ $t('controlTower.components.chartIndicator.offendingSubtitle') }}
      </span>
    </v-footer>
  </v-card>
</template>

<script>
import IndividualIndicator from './IndividualIndicator.vue'
export default {
  name: 'IndividualList',
  components: { IndividualIndicator },
  props: {
    title: {
      type: String
    },
    values: {
      type: Array
    }
  },
  computed: {
    containsOfenders() {
      return this.values.filter((o) => o.offender).length
    },
    containsEffective() {
      return this.values.filter((o) => o.effective).length
    }
  }
}
</script>

<style lang="scss" scoped>
.list-wrapper {
  max-height: 350px;
  overflow: overlay;
}
.indicadores-list-card {
  width: 100%;
}
.list-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 277px;
}

hr {
  border: 1px solid #616575;
  opacity: 1;
  margin: 0px 25px 0px 25px;
}

.default-text-layout {
  text-align: left;
  font: normal normal bold 14px/63px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}

.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer-font {
  text-align: left;
  font: normal normal normal 12px/16px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}

::-webkit-scrollbar {
  width: 3px;
  height: 85px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b9b9b9;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #27bee7;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #27bee7;
}

.expander {
  color: #959db5 !important;
  transform: rotate(45deg);
}
</style>
