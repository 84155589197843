import { api, createCrudService } from '@cargill/shared'

const service = createCrudService('/api/optimizationQueueEntity', api)

service.getActiveOptimizationQueueAsNotification = async () => {
  const response = await api.get(
    `/api/optimizationQueueEntity/getActiveOptimizationQueueAsNotification`
  )
  return response.data
}

service.exportLogs = async function (id) {
  this.logRequest('exportLogs')
  try {
    const response = await api.get(
      `/api/optimizationQueueEntity/exportLogs/${id}`,
      {
        responseType: 'blob'
      }
    )
    return service.getFileResponseData(response)
  } catch (e) {
    this.handleError(e)
    throw e
  }
}

export default service
