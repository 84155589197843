<template>
  <bc-popover v-model="menu" placement="bottom-end" class="offset">
    <template v-slot:activator="{ on, attrs }">
      <bc-btn
        icon
        class="filter-icon"
        v-bind="attrs"
        v-on="on"
        :max-width="30"
        :max-height="30"
      >
        <bc-icon color="#959db5">fas fa-user</bc-icon>
      </bc-btn>
    </template>

    <template v-slot:header>
      {{ $t('application.misc.profile') }}
    </template>

    <div class="card-container">
      <div class="content">
        <div class="flex space">
          <div class="profile">
            <bc-avatar>
              <bc-icon color="#7e7d7d">fas fa-user</bc-icon>
            </bc-avatar>
          </div>
          <div class="name-wrapper">
            <span class="name">{{ userRealName }}</span>
            <span class="role">{{ userEid }}</span>
            <span class="role">{{ username }}</span>
          </div>
        </div>
      </div>
      <bc-divider></bc-divider>

      <div class="language">
        <bc-select
          filled
          :value="selectedLanguage"
          :items="languages"
          :returnObject="false"
          @change="updateLanguage"
        ></bc-select>
      </div>

      <bc-divider></bc-divider>

      <bc-spacer></bc-spacer>
      <div>
        <bc-btn block @click="logout()" class="logout" color="link-gray">
          {{ $t('application.misc.logout') }}
        </bc-btn>
      </div>
    </div>
  </bc-popover>
</template>

<script>
import { authService, useUserSettings } from '@cargill/shared'
import { mapActions, mapState } from 'pinia'

const languages = [
  { text: 'English', value: 'en' },
  { text: 'Português', value: 'pt-BR' }
]

export default {
  data() {
    return {
      username: null,
      userEid: null,
      userRealName: null,
      menu: false,
      languages
    }
  },
  mounted() {
    this.getUserData()
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },
    ...mapState(useUserSettings, {
      selectedLanguage: (state) => state.getLanguage()
    })
  },
  methods: {
    async getUserData() {
      this.username = await authService.getUsername()
      this.userEid = this.username ? this.username.split('@')[0] : ''
      this.userRealName = await authService.getUserRealName()
    },
    getImageSrc(avatar) {
      if (avatar == null) return ''
      const mime = avatar.mime
      const data = avatar.data
      return `data:${mime};base64,${data}`
    },
    logout() {
      authService.doLogout()
    },
    ...mapActions(useUserSettings, ['updateLanguage'])
  }
}
</script>

<style scoped lang="scss">
.offset {
  margin-left: 5px;
}
.card-container {
  margin: 0 -20px -20px;
  min-width: 430px;
}
.content {
  .flex {
    display: flex;
    align-items: center;
  }
  .profile {
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    background: white;
    border-radius: 50%;
  }
  .space {
    padding: 15px 20px;
  }
  .name {
    text-transform: capitalize;
    color: var(--bc-primary);
    font-size: 1rem;
    font-weight: bold;
  }

  .role {
    text-transform: none;
    font-weight: 400;
    color: var(--bc-popover-body-color);
  }

  .name-wrapper {
    display: flex;
    margin-left: 1rem;
    flex-direction: column;
  }
}
.language {
  margin: 10px;
}
.logout {
  &#{&}.v-btn {
    justify-content: flex-end;
    padding: 20px !important;
  }
}
</style>
