<template>
  <div class="container">
    <div class="progress-line">
      <v-progress-linear
        disable
        :value="value.value"
        height="8"
        :color="color"
        background-color="#252B38"
      ></v-progress-linear>
    </div>
    <div class="descriptionWrapper">
      <div>
        <span v-bind:style="{ color: color }" class="value"
          >{{ value.value }}%</span
        >
        <span class="default-text">{{ value.label }}</span>
      </div>
      <div v-if="value.effective != null && value.offender != null">
        <span class="default-text">E {{ value.effective }}</span> |
        <span class="default-text">O {{ value.offender }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndividualIndicator',
  components: {},
  props: {
    color: {
      type: String
    },
    value: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  margin-left: initial;
}

.progress-line {
  flex: 1;
  padding: 0px 15px 0px 15px;
}

.descriptionWrapper {
  flex: 1;
  color: #959db5;
}

.value {
  text-align: left;
  font: normal normal bold 18px/24px Aller;
  letter-spacing: 0px;
  opacity: 1;
  margin-right: 10px;
}

.default-text {
  text-align: left;
  font: normal normal normal 12px/20px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}
</style>
