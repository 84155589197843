import { api, i18n, handleError, notify } from '@cargill/shared'
import _ from 'lodash'
import optimizationStatus from '../constants/optimizationStatus'

const activeStatus = new Set([
  optimizationStatus.pending,
  optimizationStatus.readingSolution,
  optimizationStatus.writingResults,
  optimizationStatus.queued,
  optimizationStatus.creatingInput,
  optimizationStatus.requestingOptimizationStop,
  optimizationStatus.inputCreationQueued,
  optimizationStatus.waitingForLogs,
  optimizationStatus.logReading,
  optimizationStatus.writingLogs
])

const finishedStatus = new Set([
  optimizationStatus.optimal,
  optimizationStatus.finished,
  optimizationStatus.error,
  optimizationStatus.errorAutomaticCapacity,
  optimizationStatus.userInterrupted,
  optimizationStatus.errorWritingInput
])

const canRequestStopStatus = new Set([
  optimizationStatus.pending,
  optimizationStatus.readingSolution,
  optimizationStatus.writingResults,
  optimizationStatus.queued,
  optimizationStatus.creatingInput,
  optimizationStatus.inputCreationQueued,
  optimizationStatus.waitingForLogs,
  optimizationStatus.logReading,
  optimizationStatus.writingLogs
])

const service = {}

service.optimize = async () => {
  try {
    const response = await api.post('/api/optimization/optimize')
    return response.data
  } catch (error) {
    handleError(error)
    throw error
  }
}

service.isFinished = (optmizationQueue) => {
  return finishedStatus.has(service.getStatus(optmizationQueue))
}

service.isActive = (optmizationQueue) => {
  return activeStatus.has(service.getStatus(optmizationQueue))
}

service.getStatus = (optmizationQueue) => {
  return _.isString(optmizationQueue.status)
    ? _.camelCase(optmizationQueue.status)
    : _.camelCase(optmizationQueue.status.value)
}

service.canRequestStop = (optmizationQueue) => {
  return canRequestStopStatus.has(service.getStatus(optmizationQueue))
}

service.createFinishedAlert = (optmizationQueue) => {
  const alertId = `optimizationQueueEntity-${optmizationQueue.optimizationQueueEntityId}`
  const status = service.getStatus(optmizationQueue)
  if (
    status == optimizationStatus.optimal ||
    status == optimizationStatus.finished
  ) {
    return {
      id: alertId,
      type: 'success',
      message: i18n.t('application.pages.optimization.finishedSuccessfully', {
        scenario: optmizationQueue.scenarioName
      })
    }
  } else if (
    status == optimizationStatus.error ||
    status == optimizationStatus.errorWritingInput ||
    status == optimizationStatus.errorAutomaticCapacity
  ) {
    return {
      id: alertId,
      type: 'error',
      message: i18n.t('application.pages.optimization.finishedWithError', {
        scenario: optmizationQueue.scenarioName
      })
    }
  } else if (status == optimizationStatus.userInterrupted) {
    return {
      id: alertId,
      type: 'success',
      message: i18n.t(
        'application.pages.optimization.interruptedSuccessfully',
        {
          scenario: optmizationQueue.scenarioName
        }
      )
    }
  }
  return null
}

service.stop = async (optimizationQueueId) => {
  try {
    const response = await api.put(
      `/api/optimization/stop/${optimizationQueueId}`
    )
    const stopped = response.data
    if (stopped) {
      notify.success({
        title: i18n.t(
          'application.pages.optimization.requestToStopOptimizationSent'
        )
      })
    } else {
      notify.error({
        title: i18n.t(
          'application.pages.optimization.couldNotRequestOptimizationStop'
        )
      })
    }
    return stopped
  } catch (error) {
    handleError(error)
    throw error
  }
}

service.stopCurrent = async () => {
  try {
    const response = await api.put(`/api/optimization/stopCurrent`)
    const sent = response.data
    if (sent) {
      notify.success({
        title: i18n.t(
          'application.pages.optimization.requestToStopOptimizationSent'
        )
      })
    } else {
      notify.error({
        title: i18n.t(
          'application.pages.optimization.couldNotRequestOptimizationStop'
        )
      })
    }
    return sent
  } catch (error) {
    handleError(error)
    throw error
  }
}

service.current = async () => {
  try {
    const response = await api.get(`/api/optimization/current`)
    return response.data
  } catch (error) {
    handleError(error)
    throw error
  }
}

export default service
