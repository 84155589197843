<template>
  <div class="dashboard-wrapper">
    <!-- filter -->
    <div class="content-label" style="margin-bottom: 0.5rem">
      <div>{{ $t('controlTower.pages.capacityUtilization.title') }}</div>
      <div class="filter">
        <span v-show="displayRange.length">
          {{ $t('controlTower.pages.capacityUtilization.filter.period') }}:
          {{
            filterRange.length
              ? `${displayRange[0]} ${$t(
                  'controlTower.pages.forecastSales.till'
                )} ${displayRange[1]} `
              : ''
          }}
        </span>

        <bc-menu
          v-model="menu"
          left
          :close-on-content-click="false"
          offset-x
          offset-y
          min-width="400"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="filter-icon" v-bind="attrs" v-on="on">
              <v-icon>fas fa-filter</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-toolbar color="#1f2126">
              <v-toolbar-title class="default-title-layout">
                {{ $t('controlTower.pages.forecastSales.filter') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <bc-radio-group v-model="filterType" row>
                    <bc-radio
                      :label="
                        $t(
                          'controlTower.pages.capacityUtilization.filter.period'
                        )
                      "
                      value="period"
                    ></bc-radio>
                    <bc-radio
                      :label="
                        $t(
                          'controlTower.pages.capacityUtilization.filter.harvest'
                        )
                      "
                      value="harvest"
                    ></bc-radio>
                    <bc-radio
                      :label="
                        $t(
                          'controlTower.pages.capacityUtilization.filter.fiscalYear'
                        )
                      "
                      value="fiscal"
                    ></bc-radio>
                  </bc-radio-group>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="filterType == 'period'">
                <v-list-item-content>
                  <datepicker-range
                    v-model="filterRange"
                    @input="validateRange()"
                    :maxDate="maxDate"
                    :minDate="minDate"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="filterType == 'harvest'">
                <v-list-item-content>
                  <bc-select
                    filled
                    class="input-size"
                    v-model="filterHarvestYear"
                    :items="harvestType"
                    item-text="label"
                    item-value="value"
                    :menu-props="{ top: true, offsetY: true }"
                    :label="
                      $t(
                        'controlTower.pages.capacityUtilization.filter.harvest'
                      )
                    "
                  ></bc-select>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-show="filterType == 'fiscal'">
                <v-list-item-content>
                  <bc-select
                    filled
                    class="input-size"
                    v-model="filterFiscalYear"
                    :items="fiscalType"
                    item-text="label"
                    item-value="value"
                    :menu-props="{ top: true, offsetY: true }"
                    :label="
                      $t(
                        'controlTower.pages.capacityUtilization.filter.fiscalYear'
                      )
                    "
                  ></bc-select>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="saveFilter">
                {{ $t('application.actions.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </bc-menu>
      </div>
    </div>
    <!-- End filter -->
    <div v-if="charts.length">
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel v-for="(chartData, index) in charts" :key="index">
          <v-expansion-panel-header>
            <template v-slot:actions>
              <v-icon class="header-icon" color="#959db5">
                fas fa-caret-down
              </v-icon>
            </template>
            <div class="header-text">{{ chartTitles[index] }}</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="forecast-wrapper">
              <section id="graph">
                <harvest-fiscal-consolidated
                  :percentHarvest="chartData.harvest"
                  :percentFiscalYear="chartData.fiscalYear"
                  :harvestYear="hYear"
                  :fiscalYear="fYear"
                ></harvest-fiscal-consolidated>

                <v-card class="forecast-card" color="#252C39">
                  <v-toolbar color="#2B323F">
                    <v-toolbar-title class="default-title-layout">{{
                      graphTitle
                    }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                  <div class="chart-container">
                    <div class="report-chart">
                      <high-chart
                        :ref="chartRefs[index]"
                        :chartInputData="chartData"
                        :id="chartRefs[index]"
                        v-if="chartData.forecast"
                        :unity="'%'"
                        @onChartDataClick="
                          filterSelectedIndicators($event.event.point)
                        "
                        hasHighlightOnClick
                      ></high-chart>
                    </div>
                    <div class="consolidated-wrapper">
                      <div class="cover-area">
                        <div class="inner-title-text">
                          <div>
                            {{
                              $t('controlTower.pages.capacityUtilization.ibp')
                            }}
                          </div>
                          <div>
                            {{
                              $t(
                                'controlTower.pages.capacityUtilization.consolidated'
                              )
                            }}
                          </div>
                          <span id="period">
                            {{ consolidatedStart }}
                            {{
                              $t('controlTower.pages.capacityUtilization.to')
                            }}
                            {{ consolidatedEnd }}
                          </span>
                        </div>
                        <div class="inner-text">
                          {{ chartData.consolidated
                          }}<span class="percent">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </section>

              <section class="indicators">
                <indicator
                  :indicators="chartData.indicators"
                  :filter="chartData.filterSelected"
                  v-if="reload"
                  :title="
                    $t('controlTower.pages.forecastSales.indicator.title')
                  "
                />
              </section>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import HighChart from '../components/charts/HighChart'
import Indicator from '../components/chart-indicators/Indicator.vue'
import HarvestFiscalConsolidated from '../components/chart-consolidated/HarvestFiscalConsolidated'
import moment from 'moment'
import DatepickerRange from '../components/date-picker/DatepickerRange'
import service from '../api/capacityUtilizationService.js'
import HarvestType from '../constants/HarvestType.js'
import FiscalType from '../constants/FiscalType.js'

const start = moment().startOf('year').format('YYYY-MM-DD')
const end = moment().endOf('year').format('YYYY-MM-DD')

export default {
  name: 'CapacityUtilization',
  components: {
    HighChart,
    Indicator,
    HarvestFiscalConsolidated,
    DatepickerRange
  },
  data() {
    return {
      graphTitle: this.$t('controlTower.pages.capacityUtilization.graphTitle'),
      forecastData: {
        railwayWaterwayStuffing: {},
        shipowner: {},
        warehouse: {}
      },
      chartRefs: ['railwayWaterwayStuffing', 'shipowner', 'warehouse'],
      chartTitles: [
        this.$t('controlTower.pages.capacityUtilization.railroadCapacity'),
        this.$t('controlTower.pages.capacityUtilization.shipownerCapacity'),
        this.$t('controlTower.pages.capacityUtilization.werehouse')
      ],
      hYear: 0,
      fYear: 0,
      consolidatedPercent: null,
      initialData: [],
      labels: [],
      menu: false,
      filter: false,
      filterRange: [start, end],
      lastFilterRange: [start, end],
      displayRange: [],
      maxDate: null,
      minDate: null,
      filterType: 'period',
      filterHarvestYear: null,
      filterFiscalYear: null,
      harvestType: null,
      fiscalType: null,
      reload: true,
      panel: [0]
    }
  },
  async mounted() {
    this.$root.$on('clickForecastScatter', (event) => {
      this.setupIndicators(event.item, event.chart)
      event.item
        ? (this.forecastData[event.chart].filterSelected = event.item)
        : (this.forecastData[event.chart].filterSelected = null)

      this.reloadIndicators()
    })
    await this.fetchData()
    //this.setupIndicators(null)
  },
  created() {
    const translate = (x) => (x.label = this.$t(x.key) + ' ' + x.value)
    this.harvestType = HarvestType
    this.harvestType.forEach(translate)

    this.fiscalType = FiscalType
    this.fiscalType.forEach(translate)
  },
  beforeDestroy() {
    this.$root.$off('clickForecastScatter')
  },
  computed: {
    consolidatedStart() {
      return this.dateFormat(this.filterRange[0], 'MMM/YY').toUpperCase()
    },
    consolidatedEnd() {
      return this.dateFormat(this.filterRange[1], 'MMM/YY').toUpperCase()
    },
    charts() {
      return [
        this.forecastData.railwayWaterwayStuffing,
        this.forecastData.shipowner,
        this.forecastData.warehouse
      ]
    }
  },
  methods: {
    filterSelectedIndicators(event) {
      this.setupIndicators(event, event.chart)
      event.item
        ? (this.forecastData[event.chart].filterSelected = event.item)
        : (this.forecastData[event.chart].filterSelected = null)

      this.reloadIndicators()
    },
    dateFormat(date, format) {
      return date == null ? '' : moment(date).format(format)
    },
    reloadIndicators() {
      this.reload = false
      setTimeout(() => {
        this.reload = true
      }, 50)
    },
    getSelectedIndicators(_selected, _chart) {
      if (_selected == null) this.forecastData[_chart].initialIndicators

      if (!_selected.selected) {
        return _selected.indicators
      } else {
        return this.forecastData[_chart].initialIndicators
      }
    },
    validateFilter() {
      if (this.filterType != 'period') {
        this.filterRange = []
      }

      if (this.filterType != 'harvest') {
        this.filterHarvestYear = null
      }

      if (this.filterType != 'fiscal') {
        this.filterFiscalYear = null
      }

      if (this.filterType == 'period' && this.filterRange[1] == null) {
        this.notify.error({
          title: this.$t('controlTower.errors.requiredEndPeriod')
        })
        this.filterRange = this.lastFilterRange
        return false
      }
      return true
    },
    setDisplayRange() {
      this.displayRange = [
        this.dateFormat(this.filterRange[0], this.$t('application.dateFormat')),
        this.dateFormat(this.filterRange[1], this.$t('application.dateFormat'))
      ]
    },
    updateFilterRange(filterRange) {
      this.filterRange = filterRange.map((x) =>
        this.dateFormat(x, 'YYYY-MM-DD')
      )
      this.lastFilterRange = [...this.filterRange]
      this.setDisplayRange()
    },
    async fetchData() {
      if (!this.validateFilter()) {
        return
      }

      this.forecastData = {
        railwayWaterwayStuffing: {},
        shipowner: {},
        warehouse: {}
      }

      const data = await service.calculate(
        this.filterRange[0],
        this.filterRange[1],
        this.filterType === 'harvest' ? this.filterHarvestYear : null,
        this.filterType === 'fiscal' ? this.filterFiscalYear : null
      )
      this.updateFilterRange([data.filter.startDate, data.filter.endDate])

      this.setupOptions(data)
    },
    setupIndicators(_selected, _chart) {
      const data = this.getSelectedIndicators(_selected, _chart)
      this.forecastData[_chart].indicators = [
        {
          title: this.$t(
            'controlTower.pages.capacityUtilization.indicators.utilityOpen'
          ),
          values: data
        }
      ]
    },
    setupOptions(_data) {
      let vm = this

      this.fYear = _data.fiscalYear
      this.hYear = _data.harvestYear
      const ignoredKeys = new Set(['filter', 'fiscalYear', 'harvestYear'])
      Object.keys(_data)
        .filter((key) => !ignoredKeys.has(key))
        .forEach((key) => {
          vm.forecastData[key].forecast = _data[key].capacityUtilization
          vm.forecastData[key].indicators = _data[key].indicators
          vm.forecastData[key].consolidated = _data[key].ibPconsolidated
          vm.forecastData[key].harvest = _data[key].harvest
          vm.forecastData[key].harvestYear = _data[key].harvestYear
          vm.forecastData[key].fiscalYear = _data[key].fiscalYear
          vm.forecastData[key].filterSelected = null
          vm.forecastData[key].chart = key

          //indicators
          vm.forecastData[key].indicators = [
            {
              values: vm.forecastData[key].indicators,
              title: this.$t(
                'controlTower.pages.capacityUtilization.indicators.utilityOpen'
              )
            }
          ]

          vm.forecastData[key].initialIndicators = _data[key].indicators

          //create labels
          vm.forecastData[key].forecast.forEach((forecast) => {
            forecast.dateLabel = this.dateFormat(
              forecast.date,
              'MMM/YY'
            ).toUpperCase()
            forecast.chart = key
          })

          vm.forecastData[key].labels = [
            ...new Set(vm.forecastData[key].forecast.map((o) => o.dateLabel))
          ]

          vm.forecastData[key].forecast.forEach((forecast) => {
            forecast.y = forecast.value
          })

          //dataSets
          const fulfilled = vm.forecastData[key].forecast.filter((o) =>
            moment(o.date).isBefore(moment())
          )
          const planned = vm.forecastData[key].forecast.filter((o) =>
            moment(o.date).isAfter(moment())
          )

          vm.forecastData[key].chartDataSets = [
            {
              name: this.$t('controlTower.pages.capacityUtilization.fulfilled'),
              type: 'bar',
              defaultBackgroundColor: '#2D949B',
              data: fulfilled
            },
            {
              type: 'bar',
              name: this.$t('controlTower.pages.capacityUtilization.planned'),
              defaultBackgroundColor: '#959DB5',
              data: planned
            }
          ]
        })
    },
    validateRange() {
      if (this.filterRange.length == 1) {
        this.maxDate = moment(this.filterRange[0])
          .add(12, 'month')
          .format('YYYY-MM-DD')

        this.minDate = this.dateFormat(this.filterRange[0], 'YYYY-MM-DD')
      }
      if (this.filterRange.length == 2) {
        this.filter = false
        this.minDate = null
        this.maxDate = null
      }
    },
    async saveFilter() {
      this.menu = false

      await this.fetchData()
      this.reloadIndicators()
    }
  }
}
</script>
<style lang="scss" scoped>
.header-text {
  order: 1;
}
.header-icon {
  font-size: 1rem;
  order: 0;
  margin-right: 1rem;
}
.dashboard-wrapper {
  margin: 15px;
}
.content-label {
  color: var(--bc-title-color);
  font-family: var(--bc-font-regular);
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 1rem;
  background-color: var(--bc-bg-header);
  margin-top: 1.3rem;
  align-items: center;
  i {
    cursor: pointer;
  }
  .filter-icon {
    color: #959db5;
    margin-left: 0.5rem;
    i {
      font-size: 1rem;
    }
  }
}
.chart-container {
  display: flex;
  .report-chart {
    padding: 1rem;
    width: 1050px;
    min-height: 455px;
    flex-grow: 2;
  }
  .consolidated-wrapper {
    display: flex;
    max-width: 240px;
    justify-content: center;
    align-items: center;
    width: 100%;
    .cover-area {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100% - 64px);
      background: #2b323f;
      margin: 1rem 1rem;
      flex-direction: column;
    }
    .inner-text {
      font-size: 4.5rem;
      color: #24bbef;
      font-weight: bold;
      .percent {
        font-size: 3rem;
        position: relative;
        top: 1rem;
      }
    }
    .inner-title-text {
      color: #959db5;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.2rem;
      #period {
        font-size: 1rem;
      }
    }
  }
}

.expander {
  color: #959db5 !important;
  transform: rotate(45deg);
}
.default-title-layout {
  text-align: left;
  font: normal normal bold 14px/63px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}
.forecast-card {
  width: 100%;
  margin-left: 0.5rem;
}
section {
  display: flex;
  margin-top: 0.5rem;
}
.forecast-wrapper {
  background: #232834;
  padding: 1rem 1rem;
}

.v-expansion-panel-header {
  background: #262c39;
  color: #959db5;
  text-transform: uppercase;
}

.v-expansion-panel-content {
  background: #232834;
}
.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
