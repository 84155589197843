const HarvestType = [
  {
    key: 'controlTower.constantes.safra',
    value: 2020
  },
  {
    key: 'controlTower.constantes.safra',
    value: 2021
  }
]

export default HarvestType
