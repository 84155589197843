<template>
  <v-card class="indicators-card" color="#252C39">
    <v-toolbar color="#2B323F">
      <v-toolbar-title class="default-title-layout">
        <i
          v-if="filter != null"
          class="far fa-angle-left"
          style="margin-right: 0.5rem"
        ></i>
        {{ fullTitle }}</v-toolbar-title
      >
    </v-toolbar>
    <div class="indicator-container">
      <div
        class="indicator-list-item"
        v-for="indicator in indicators"
        :key="indicator.title"
      >
        <indicator-list
          :title="indicator.title"
          :values="indicator.values"
        ></indicator-list>
      </div>
    </div>
  </v-card>
</template>

<script>
import IndicatorList from './IndicatorList.vue'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'Indicator',
  components: { IndicatorList },
  props: {
    indicators: {
      type: Array
    },
    title: {
      type: String
    },
    filter: {
      type: Object,
      default: null
    },
    dateFormat: {
      type: String,
      default: 'application.fullMonthYearChart'
    }
  },
  data() {
    return {
      indicatorsFilter: [],
      month: null
    }
  },
  mounted() {
    moment.locale(this.$i18n.locale)
  },
  computed: {
    fullTitle() {
      return this.filter
        ? `${this.title} / ${moment(this.filter.date).format(
            this.$t(this.dateFormat)
          )}`
        : this.title
    }
  },
  methods: {
    isInvalidEvent(event) {
      return (
        event?.labels == null ||
        event?.selectItem?._index == null ||
        event.labels[event.selectItem._index] == null
      )
    },
    processIndicadors(indicators) {
      indicators.forEach((indicator) => {
        let values = _.sortBy(indicator.values, (v) => Number(v.percent))
        values = values.slice(0, Math.min(5, values.length))
        indicator.values = values
      })
      return indicators
    },
    filterByPeriod(startDate, endDate) {
      const indicators = _.cloneDeep(this.indicators ?? [])
      indicators.forEach((indicator) => {
        indicator.values = (indicator.values ?? []).filter(
          (value) => value.date >= startDate && value.date < endDate
        )
      })
      this.indicatorsFilter = this.processIndicadors(indicators)
    }
  },
  watch: {
    indicators: function () {
      this.indicatorsFilter = this.processIndicadors(
        _.cloneDeep(this.indicators ?? [])
      )
    },
    '$i18n.locale': function () {
      moment.locale(this.$i18n.locale)
    }
  }
}
</script>

<style lang="scss" scoped>
.indicators-card {
  width: 100%;
}
.indicator-container {
  display: flex;
}
.indicator-list-item {
  flex: 1;
  padding: 15px;
}

.default-title-layout {
  text-align: left;
  font: normal normal bold 14px/63px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}
</style>
