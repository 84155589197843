<template>
  <div>
    <!-- filter -->
    <div class="content-label" style="margin-bottom: 0.5rem">
      <div>{{ $t('controlTower.pages.onTimeInFull.title') }}</div>
      <div class="filter">
        <span class="targetOptions">
          <span v-show="displayRange.length">
            {{ $t('controlTower.pages.onTimeInFull.period') }}:
            {{
              filterRange.length
                ? `${displayRange[0]} ${$t(
                    'controlTower.pages.onTimeInFull.till'
                  )} ${displayRange[1]} `
                : ''
            }}
          </span>
          <span>|</span>
          <i
            @click="setActualTarget('weekly')"
            class="fas fa-calendar-week targetOption"
            :class="actualTarget != 'monthly' ? 'selected' : ''"
          ></i>
          <i
            @click="setActualTarget('monthly')"
            class="fas fa-calendar-alt targetOption"
            :class="actualTarget == 'monthly' ? 'selected' : ''"
          ></i>
          <span>|</span>

          <bc-menu
            v-model="menu"
            left
            :close-on-content-click="false"
            offset-x
            offset-y
            min-width="400"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="filter-icon" v-bind="attrs" v-on="on">
                <v-icon>fas fa-filter</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-toolbar color="#1f2126">
                <v-toolbar-title class="default-title-layout">
                  {{ $t('controlTower.pages.onTimeInFull.filter') }}
                </v-toolbar-title>
              </v-toolbar>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <bc-radio-group v-model="filterType" row>
                      <bc-radio
                        :label="
                          $t(
                            'controlTower.pages.capacityUtilization.filter.period'
                          )
                        "
                        value="period"
                      ></bc-radio>
                      <bc-radio
                        :label="
                          $t(
                            'controlTower.pages.capacityUtilization.filter.harvest'
                          )
                        "
                        value="harvest"
                      ></bc-radio>
                      <bc-radio
                        :label="
                          $t(
                            'controlTower.pages.capacityUtilization.filter.fiscalYear'
                          )
                        "
                        value="fiscal"
                      ></bc-radio>
                    </bc-radio-group>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-show="filterType == 'period'">
                  <v-list-item-content>
                    <datepicker-range
                      v-model="filterRange"
                      @input="validateRange()"
                      :maxDate="maxDate"
                      :minDate="minDate"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-show="filterType == 'harvest'">
                  <v-list-item-content>
                    <bc-select
                      filled
                      class="input-size"
                      v-model="filterHarvestYear"
                      :items="harvestType"
                      item-text="label"
                      item-value="value"
                      :menu-props="{ top: true, offsetY: true }"
                      :label="
                        $t(
                          'controlTower.pages.capacityUtilization.filter.harvest'
                        )
                      "
                    ></bc-select>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-show="filterType == 'fiscal'">
                  <v-list-item-content>
                    <bc-select
                      filled
                      class="input-size"
                      v-model="filterFiscalYear"
                      :items="fiscalType"
                      item-text="label"
                      item-value="value"
                      :menu-props="{ top: true, offsetY: true }"
                      :label="
                        $t(
                          'controlTower.pages.capacityUtilization.filter.fiscalYear'
                        )
                      "
                    ></bc-select>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="saveFilter">
                  {{ $t('application.actions.save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </bc-menu>
        </span>
      </div>
    </div>
    <!-- End filter -->

    <div class="OnTimeInFull-wrapper">
      <section id="graph">
        <harvestFiscalConsolidated
          :percentHarvest="onTimeInFullData.harvest"
          :percentFiscalYear="onTimeInFullData.fiscalYear"
          :harvestYear="onTimeInFullData.yearHarvest"
          :fiscalYear="onTimeInFullData.yearFiscalYear"
        ></harvestFiscalConsolidated>

        <v-card class="OnTimeInFull-card" color="#252C39">
          <v-toolbar color="#2B323F">
            <v-toolbar-title class="default-title-layout">{{
              title
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <div class="chart-container">
            <div class="report-chart">
              <high-chart
                v-if="onTimeInFullData.chartDataSets"
                hasHighlightOnClick
                @onChartDataClick="filterSelectedIndicators($event.event.point)"
                :chartInputData="onTimeInFullData"
                :chart="chart"
                :unity="'%'"
              ></high-chart>
            </div>
            <div class="consolidated-wrapper">
              <div class="cover-area">
                <div class="inner-title-text">
                  <div>
                    {{ $t('controlTower.pages.onTimeInFull.OTIF') }}
                  </div>
                  <div>
                    {{ $t('controlTower.pages.onTimeInFull.consolidated') }}
                  </div>
                  <span id="period">
                    {{ consolidatedStart }}
                    {{ $t('controlTower.pages.onTimeInFull.to') }}
                    {{ consolidatedEnd }}
                  </span>
                </div>
                <div class="inner-text">
                  {{ onTimeInFullData.otifConsolidated
                  }}<span class="percent">%</span>
                </div>
              </div>
            </div>
          </div>
        </v-card>
      </section>

      <section class="indicators">
        <indicator
          :indicators="indicators"
          :filter="filterSelected"
          :title="$t('controlTower.pages.onTimeInFull.indicator.title')"
          :dateFormat="dateFormatIndicators"
        />
      </section>
    </div>
  </div>
</template>
<script>
import HarvestFiscalConsolidated from '../components/chart-consolidated/HarvestFiscalConsolidated'
import HighChart from '../components/charts/HighChart'
import Indicator from '../components/chart-indicators/Indicator.vue'
import moment from 'moment'
import DatepickerRange from '../components/date-picker/DatepickerRange'
import service from '../api/onTimeInFullService.js'
import HarvestType from '../constants/HarvestType.js'
import FiscalType from '../constants/FiscalType.js'

const defaultDateFormat = 'YYYY-MM-DD'
const start = moment().startOf('year').format(defaultDateFormat)
const end = moment().endOf('year').format(defaultDateFormat)

export default {
  name: 'OnTimeInFull',
  components: {
    HighChart,
    Indicator,
    DatepickerRange,
    HarvestFiscalConsolidated
  },
  data() {
    return {
      chart: {
        max: 12
      },
      dateFormatIndicators: 'application.fullMonthYearChart',
      scrollable: false,
      scrollableWidth: 0,
      title: this.$t('controlTower.pages.onTimeInFull.graphTitle'),
      onTimeInFullData: {
        chartDataSets: []
      },
      consolidatedPercent: null,
      initialData: [],
      chartData: [],
      labels: [],
      menu: false,
      filter: false,
      filterRange: [start, end],
      lastFilterRange: [start, end],
      displayRange: [],
      maxDate: null,
      minDate: null,
      indicators: [],
      filterSelected: null,
      actualTarget: 'monthly',
      filterType: 'period',
      filterHarvestYear: null,
      filterFiscalYear: null,
      harvestType: null,
      fiscalType: null
    }
  },
  computed: {
    consolidatedStart() {
      return this.dateFormat(
        this.filterRange[0],
        this.$t('application.monthYearChart')
      ).toUpperCase()
    },
    consolidatedEnd() {
      return this.dateFormat(
        this.filterRange[1],
        this.$t('application.monthYearChart')
      ).toUpperCase()
    },
    isWeeklyTarget() {
      return this.actualTarget == 'weekly'
    },
    isMonthlyTarget() {
      return this.actualTarget == 'monthly'
    }
  },
  async mounted() {
    await this.fetchData(this.actualTarget)
    this.setupIndicators(null)
  },
  created() {
    const translate = (x) => (x.label = this.$t(x.key) + ' ' + x.value)
    this.harvestType = HarvestType
    this.harvestType.forEach(translate)

    this.fiscalType = FiscalType
    this.fiscalType.forEach(translate)
  },
  beforeDestroy() {
    this.$root.$off('clickForecastScatter')
  },
  methods: {
    filterSelectedIndicators(event) {
      event && !event.selected
        ? (this.filterSelected = event)
        : (this.filterSelected = null)
      this.setupIndicators(event)
    },
    dateFormat(date, format) {
      return date == null ? '' : moment(date).format(format)
    },
    getSelectedIndicators(_selected) {
      if (_selected == null) return this.onTimeInFullData.indicators

      if (!_selected.selected) {
        return _selected.indicators
      } else {
        return this.onTimeInFullData.indicators
      }
    },
    async fetchData(target) {
      this.onTimeInFullData = {}
      this.actualTarget = target
      if (!this.validateFilter()) {
        return
      }
      const data = await service.calculate(
        this.filterRange.length ? this.filterRange[0] : null,
        this.filterRange.length ? this.filterRange[1] : null,
        target,
        this.filterHarvestYear ? this.filterHarvestYear.value : null,
        this.filterFiscalYear ? this.filterFiscalYear.value : null
      )

      this.dateFormatIndicators = this.isWeeklyTarget
        ? 'application.fullMonthYearChart'
        : 'application.fullMonthYearChart'

      this.updateFilterRange([data.filter.startDate, data.filter.endDate])
      this.onTimeInFullData.harvest = data.harvestResult
      this.onTimeInFullData.fiscalYear = data.fiscalYearResult
      this.onTimeInFullData.goal = data.goal
      this.onTimeInFullData.indicators = data.indicators
      this.onTimeInFullData.otifConsolidated = data.otifConsolidated
      this.onTimeInFullData.forecast = data.otifRealized
      this.onTimeInFullData.yearHarvest = data.harvestYear
      this.onTimeInFullData.yearFiscalYear = data.fiscalYear

      this.setupOptions()
      this.setupIndicators(null)
    },
    setupIndicators(_selected) {
      const data = this.getSelectedIndicators(_selected)
      this.indicators = [
        {
          title: this.$t('controlTower.pages.onTimeInFull.indicator.customers'),
          values: data.customers
        },
        {
          title: this.$t(
            'controlTower.pages.onTimeInFull.indicator.destinyCountries'
          ),
          values: data.destinyCountries
        },
        {
          title: this.$t(
            'controlTower.pages.onTimeInFull.indicator.originPorts'
          ),
          values: data.originPorts
        }
      ]
    },
    setupOptions() {
      this.onTimeInFullData.forecast.forEach((otif) => {
        if (this.actualTarget == 'monthly') {
          otif.dateLabel = this.dateFormat(otif.date, 'MMM/YYYY').toUpperCase()
        } else {
          otif.dateLabel = this.dateFormat(
            otif.date,
            this.$t('application.dateFormat')
          ).toUpperCase()
        }
      })

      this.onTimeInFullData.labels = [
        ...new Set(this.onTimeInFullData.forecast.map((o) => o.dateLabel))
      ]

      this.onTimeInFullData.forecast.forEach((forecast) => {
        forecast.y = forecast.value
      })

      this.onTimeInFullData.chartDataSets = [
        {
          name: this.$t('controlTower.pages.onTimeInFull.otifLabel'),
          type: 'bar',
          defaultBackgroundColor: '#959DB5',
          data: this.onTimeInFullData.forecast
        },
        {
          name: this.$t('controlTower.pages.onTimeInFull.metaLabel'),
          type: 'line',
          defaultBackgroundColor: '#FF9A00',
          data: this.onTimeInFullData.goal.map((o) => o.value)
        }
      ]
    },
    validateRange() {
      if (this.filterRange.length == 1) {
        this.maxDate = moment(this.filterRange[0])
          .add(12, 'month')
          .format(defaultDateFormat)

        this.minDate = this.dateFormat(this.filterRange[0], defaultDateFormat)
      }
      if (this.filterRange.length == 2) {
        this.filter = false
        this.minDate = null
        this.maxDate = null
      }
    },
    setDisplayRange() {
      this.displayRange = [
        this.dateFormat(this.filterRange[0], this.$t('application.dateFormat')),
        this.dateFormat(this.filterRange[1], this.$t('application.dateFormat'))
      ]
    },
    updateFilterRange(filterRange) {
      this.filterRange = filterRange.map((x) =>
        this.dateFormat(x, defaultDateFormat)
      )
      this.lastFilterRange = [...this.filterRange]
      this.setDisplayRange()
    },
    saveFilter() {
      this.menu = false
      this.fetchData(this.actualTarget)
    },
    setActualTarget(target) {
      if (this.actualTarget != target) {
        this.fetchData(target)
      }
    },
    validateFilter() {
      if (this.filterType != 'period') {
        this.filterRange = []
      }

      if (this.filterType != 'harvest') {
        this.filterHarvestYear = null
      }

      if (this.filterType != 'fiscal') {
        this.filterFiscalYear = null
      }

      if (this.filterType == 'period' && this.filterRange[1] == null) {
        this.notify.error({
          title: this.$t('controlTower.errors.requiredEndPeriod')
        })
        this.filterRange = this.lastFilterRange
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.content-label {
  color: var(--bc-title-color);
  font-family: var(--bc-font-regular);
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 1rem;
  background-color: var(--bc-bg-header);
  margin-top: 1.3rem;
  align-items: center;
  i {
    cursor: pointer;
  }
  .filter-icon {
    color: #959db5;
    margin-left: 0.5rem;
    i {
      font-size: 1rem;
    }
  }
}
.report-chart {
  padding: 1rem;
}
.expander {
  color: #959db5 !important;
  transform: rotate(45deg);
}
.default-title-layout {
  text-align: left;
  font: normal normal bold 14px/63px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}
.OnTimeInFull-card {
  width: 100%;
  margin-left: 0.5rem;
}
section {
  display: flex;
  margin-top: 0.5rem;
}
.OnTimeInFull-wrapper {
  background: #232834;
  padding: 1rem 1rem;
}
.chart-container {
  display: flex;
  .report-chart {
    padding: 1rem;
    width: 1050px;
    flex-grow: 2;
  }
  .consolidated-wrapper {
    display: flex;
    max-width: 240px;
    justify-content: center;
    align-items: center;
    width: 100%;
    .cover-area {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: calc(100% - 64px);
      background: #2b323f;
      margin: 1rem 1rem;
      flex-direction: column;
    }
    .inner-text {
      font-size: 5.5rem;
      color: #24bbef;
      font-weight: bold;
      .percent {
        font-size: 3rem;
        position: relative;
        top: 1rem;
      }
    }
    .inner-title-text {
      color: #959db5;
      text-transform: uppercase;
      text-align: center;
      font-size: 1.2rem;
      #period {
        font-size: 1rem;
      }
    }
  }
}

.selected {
  color: #28bae7;
}

.targetOptions {
  display: flex;
  align-items: center;
  .targetOption {
    padding-left: 3px;
    padding-right: 3px;
  }
}
</style>
