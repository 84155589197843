<template>
  <bc-app>
    <cargill-header :menuItems="filteredMenuItems"></cargill-header>
    <bc-main>
      <bc-breadcrumbs-router class="cargill-bc"></bc-breadcrumbs-router>
      <alert-notification
        :alerts="alerts"
        @onClose="onCloseAlert"
      ></alert-notification>
      <router-view :key="forceReload" />

      <div v-if="isMainScreen" class="module-selector-box">
        <div>
          <div class="module-selector-header">
            {{ $t('application.misc.selectAModule') }}
          </div>
          <bc-card class="module-selector">
            <bc-box-selector
              @change="onBoxSelectorChange"
              boxColumns="4"
              :value="selectedModule"
              :options="boxSelectorData"
            ></bc-box-selector>
          </bc-card>
        </div>
      </div>
    </bc-main>

    <bc-loading size="lg" :blockUi="true" v-if="spinnerVisible" />
    <cargill-confirm-dialog
      v-model="showConfirmOpen"
      type="warning"
      :class="'height-fix'"
      icon="fa-exclamation-circle"
      :title="
        $t('application.pages.scenarioCargill.confirmOpenScenario', {
          scenario: scenario.newScenario.name
        })
      "
      :subtitle="
        $t('application.pages.scenarioCargill.scenarioWillBeClosed', {
          scenario: scenario.oldScenario.name
        })
      "
      :confirmLabel="$t('application.pages.scenarioCargill.openScenario')"
      :cancelLabel="$t('application.actions.cancel')"
      @confirm="openScenario"
      @cancel="showConfirmOpen = false"
      :askAgainLabel="$t('application.pages.scenarioCargill.notShowAgain')"
      @onCheckChange="onCheckChange($event)"
    ></cargill-confirm-dialog>

    <cargill-confirm-dialog
      v-model="showConfirmClose"
      type="warning"
      :class="'height-fix'"
      icon="fa-exclamation-circle"
      :title="
        $t('application.pages.scenarioCargill.confirmCloseScenario', {
          scenario: scenario.oldScenario.name
        })
      "
      :subtitle="$t('application.pages.scenarioCargill.scenarioClose')"
      :confirmLabel="$t('application.pages.scenarioCargill.closeScenario')"
      :cancelLabel="$t('application.actions.cancel')"
      @confirm="closeScenario"
      @cancel="showConfirmClose = false"
      :askAgainLabel="$t('application.pages.scenarioCargill.notShowAgain')"
      @onCheckChange="onCheckCloseChange($event)"
    ></cargill-confirm-dialog>
    <cargill-footer></cargill-footer>
  </bc-app>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BcApp,
  BcMain,
  BcBreadcrumbsRouter,
  BcLoading,
  BcBoxSelector,
  BcCard
} from '@brain/core'
import { mapState } from 'pinia'
import { CargillFooter } from '../components/cargill-footer'
import { CargillHeader } from '../components/cargill-header'
import {
  eventHub,
  useModuleOpen,
  getModules,
  useUserSettings
} from '@cargill/shared'
import CargillConfirmDialog from '../components/confirm-dialog/CargillConfirmDialog'
import AlertNotification from '../components/alert-notification/AlertNotification'
import menuItems from '../router/menu'
import { dataPermissionSyncService } from '@cargill/shared'
import optimizationService from '../api/optimizationService'

window.dataPermissionSyncService = dataPermissionSyncService

const { getModuleOpen, getMenuItems, updateMenuItems, updateModuleOpen } =
  useModuleOpen()

const { getUserSettings } = useUserSettings()

export default defineComponent({
  name: 'Main',
  components: {
    BcApp,
    BcMain,
    CargillFooter,
    BcBreadcrumbsRouter,
    CargillHeader,
    BcLoading,
    CargillConfirmDialog,
    BcBoxSelector,
    BcCard,
    AlertNotification
  },
  data() {
    return {
      loadingCount: 0,
      showConfirmOpen: false,
      showConfirmClose: false,
      scenario: {
        newScenario: {},
        oldScenario: {}
      },
      showAskAgain: false,
      showCloseAskAgain: false,
      alerts: [],
      forceReload: 0
    }
  },
  methods: {
    reload() {
      this.forceReload++
    },
    incrementLoading() {
      this.loadingCount++
    },
    decrementLoading() {
      if (this.loadingCount > 0) {
        this.loadingCount--
      }
    },
    openScenario() {
      eventHub.$emit('open-scenario', this.scenario, this.showAskAgain)
    },
    closeScenario() {
      eventHub.$emit('close-scenario', this.scenario, this.showCloseAskAgain)
    },
    showConfirmModal() {
      this.showConfirmOpen = true
    },
    showConfirmCloseScenarioModal() {
      this.showConfirmClose = true
    },
    onCheckChange(_val) {
      this.showAskAgain = _val
    },
    onCheckCloseChange(_val) {
      this.showCloseAskAgain = _val
    },
    onBoxSelectorChange(newBoxSelector) {
      updateModuleOpen(newBoxSelector.module, menuItems, this)
    },
    addAlert(_alert) {
      const newAlerts = this.alerts.filter((alert) => _alert.id != alert.id)
      newAlerts.push(_alert)
      this.alerts = newAlerts
    },
    onCloseAlert(_alert) {
      const newAlerts = this.alerts.filter((alert) => _alert.id != alert.id)
      this.alerts = newAlerts
    },
    optimizationUpdate(optimization) {
      const userSettings = getUserSettings()
      if (
        optimizationService.isFinished(optimization) &&
        userSettings?.id == optimization?.requestingUserId
      ) {
        this.addAlert(optimizationService.createFinishedAlert(optimization))
      }
    }
  },
  created() {
    let vm = this
    eventHub.$on('before-request', this.incrementLoading)
    eventHub.$on('request-error', this.decrementLoading)
    eventHub.$on('after-response', this.decrementLoading)
    eventHub.$on('response-error', this.decrementLoading)
    eventHub.$on('confirm-open-scenario', (oldScenario, newScenario) => {
      this.scenario = { oldScenario, newScenario }
      vm.showConfirmModal()
    })
    eventHub.$on('confirm-close-scenario', (oldScenario) => {
      this.scenario.oldScenario = oldScenario
      vm.showConfirmCloseScenarioModal()
    })
    eventHub.$on('open-scenario-callback', () => {
      updateMenuItems(menuItems, this)
      this.reload()
    })
    eventHub.$on('update-user-language', () => {
      this.reload()
    })
    eventHub.$on('add-alert', this.addAlert)
    this.$notification.on(
      'OptimizationUpdateNotification',
      this.optimizationUpdate
    )
    updateMenuItems(menuItems, vm)
  },
  computed: {
    spinnerVisible() {
      return this.loadingCount > 0
    },
    isMainScreen() {
      return this.$route.path === '/'
    },
    filteredMenuItems() {
      return getMenuItems()
    },
    selectedModule() {
      return this.boxSelectorData.find((o) => o.module === getModuleOpen())
    },
    ...mapState(useUserSettings, {
      selectedLanguage: (state) => state.getLanguage()
    }),
    boxSelectorData() {
      return getModules().map((module) => {
        return {
          ...module,
          name: this.$t(module.name)
        }
      })
    }
  },
  watch: {
    selectedLanguage() {
      updateMenuItems(menuItems, this)
    }
  },
  beforeDestroy() {
    eventHub.$off('before-request', this.incrementLoading)
    eventHub.$off('request-error', this.decrementLoading)
    eventHub.$off('after-response', this.decrementLoading)
    eventHub.$off('response-error', this.decrementLoading)
    eventHub.$off('confirm-open-scenario')
    eventHub.$off('confirm-close-scenario')
    eventHub.$off('open-scenario-callback')
    eventHub.$off('update-user-language')
    this.$notification.off(
      'OptimizationUpdateNotification',
      this.optimizationUpdate
    )
  }
})
</script>

<style scoped>
.module-selector-box {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /*centers items on the cross-axis (y by default)*/
}

.module-selector-header {
  background: #1f2126;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #959db5;
  padding: var(--header-padding);
  --header-padding: 12px 40px 12px 20px;
}
.module-selector {
  display: flex;
  flex-direction: unset;
  height: 100%;
  width: 100%;
  align-items: center;
}
.cargill-bc {
  margin-left: 15px;
}
</style>
