<template>
  <div class="dashboard-wrapper">
    <!-- filter -->
    <div class="content-label" style="margin-bottom: 0.5rem">
      <div>{{ $t('controlTower.pages.forecastOrigin.title') }}</div>
      <div class="filter">
        <span v-show="displayRange.length">
          {{ $t('controlTower.pages.forecastOrigin.period') }}:
          {{
            filterRange.length
              ? `${displayRange[0]} ${$t(
                  'controlTower.pages.forecastOrigin.till'
                )} ${displayRange[1]} `
              : ''
          }}
        </span>

        <bc-menu
          v-model="menu"
          left
          :close-on-content-click="false"
          offset-x
          offset-y
          min-width="400"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="filter-icon" v-bind="attrs" v-on="on">
              <v-icon>fas fa-filter</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-toolbar color="#1f2126">
              <v-toolbar-title class="default-title-layout">
                {{ $t('controlTower.pages.forecastOrigin.filter') }}
              </v-toolbar-title>
            </v-toolbar>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <datepicker-range
                    v-model="filterRange"
                    @input="validateRange()"
                    :maxDate="maxDate"
                    :minDate="minDate"
                  />
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="saveFilter">
                {{ $t('application.actions.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </bc-menu>
      </div>
    </div>
    <!-- End filter -->

    <div class="forecast-wrapper">
      <section id="graph">
        <forecast-consolidated
          :percent="forecastData.consolidated"
        ></forecast-consolidated>

        <v-card class="forecast-card" color="#252C39">
          <v-toolbar color="#2B323F">
            <v-toolbar-title class="default-title-layout">{{
              title
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <div class="report-chart">
            <high-chart
              v-if="forecastData.chartDataSets.length"
              :chartInputData="forecastData"
              :unity="'%'"
              @onChartDataClick="filterSelectedIndicators($event.event.point)"
              hasHighlightOnClick
            ></high-chart>
          </div>
        </v-card>
      </section>

      <section class="indicators">
        <indicator
          :indicators="indicators"
          :filter="filterSelected"
          :title="$t('controlTower.pages.forecastOrigin.indicator.title')"
        />
      </section>
    </div>
  </div>
</template>
<script>
import Indicator from '../components/chart-indicators/Indicator.vue'
import ForecastConsolidated from '../components/chart-consolidated/ForecastConsolidated.vue'
import moment from 'moment'
import DatepickerRange from '../components/date-picker/DatepickerRange'
import service from '../api/originationForecastAccuracyService.js'
import HighChart from '../components/charts/HighChart'

const start = moment().startOf('year').format('YYYY-MM-DD')
const end = moment().endOf('year').format('YYYY-MM-DD')

export default {
  name: 'ForecastSales',
  components: {
    HighChart,
    Indicator,
    ForecastConsolidated,
    DatepickerRange
  },
  data() {
    return {
      title: this.$t('controlTower.pages.forecastOrigin.graphTitle'),
      forecastData: {
        forecast: [],
        chartDataSets: []
      },
      consolidatedPercent: null,
      initialData: [],
      chartData: [],
      labels: [],
      menu: false,
      filter: false,
      filterRange: [start, end],
      lastFilterRange: [start, end],
      displayRange: [],
      maxDate: null,
      minDate: null,
      indicators: [],
      filterSelected: null
    }
  },
  async mounted() {
    await this.fetchData()
    this.setupIndicators(null)
  },
  beforeDestroy() {
    this.$root.$off('clickForecastScatter')
  },
  methods: {
    filterSelectedIndicators(event) {
      event && !event.selected
        ? (this.filterSelected = event)
        : (this.filterSelected = null)
      this.setupIndicators(event)
    },
    getSelectedIndicators(_selected) {
      if (_selected == null) return this.forecastData.indicators

      if (!_selected.selected) {
        return _selected.indicators
      } else {
        return this.forecastData.indicators
      }
    },
    dateFormat(date, format) {
      return date == null ? '' : moment(date).format(format)
    },
    validateFilter() {
      if (this.filterRange[1] == null) {
        this.notify.error({
          title: this.$t('controlTower.errors.requiredEndPeriod')
        })
        this.filterRange = this.lastFilterRange
        return false
      }
      return true
    },
    async fetchData() {
      if (!this.validateFilter()) {
        return
      }
      const data = await service.calculate(
        this.filterRange[0],
        this.filterRange[1]
      )
      this.updateFilterRange(this.filterRange)
      this.forecastData.forecast = data.forecast
      this.forecastData.goal = data.goal
      this.forecastData.indicators = data.indicators
      this.forecastData.consolidated = data.consolidated

      this.setupOptions()
      this.setupIndicators(null)
    },
    setupIndicators(_selected) {
      const data = this.getSelectedIndicators(_selected)
      this.indicators = [
        {
          title: this.$t(
            'controlTower.pages.forecastOrigin.indicator.producers'
          ),
          values: data.companies
        },
        {
          title: this.$t('controlTower.pages.forecastOrigin.indicator.uf'),
          values: data.localities
        }
      ]
    },
    setupOptions() {
      this.forecastData.forecast.forEach((forecast) => {
        forecast.dateLabel = this.dateFormat(
          forecast.date,
          'MMM/YY'
        ).toUpperCase()
      })

      this.forecastData.labels = [
        ...new Set(this.forecastData.forecast.map((o) => o.dateLabel))
      ]

      this.forecastData.forecast.forEach((forecast) => {
        forecast.y = forecast.value
      })

      this.forecastData.chartDataSets = [
        {
          name: 'FA M-1',
          type: 'bar',
          defaultBackgroundColor: '#959DB5',
          data: this.forecastData.forecast
        },
        {
          name: 'Meta',
          type: 'line',
          defaultBackgroundColor: '#FF9A00',
          data: this.forecastData.goal.map((o) => o.value)
        }
      ]
    },
    validateRange() {
      if (this.filterRange.length == 1) {
        this.maxDate = moment(this.filterRange[0])
          .add(12, 'month')
          .format('YYYY-MM-DD')

        this.minDate = this.dateFormat(this.filterRange[0], 'YYYY-MM-DD')
      }
      if (this.filterRange.length == 2) {
        this.filter = false
        this.minDate = null
        this.maxDate = null
      }
    },
    setDisplayRange() {
      this.displayRange = [
        this.dateFormat(this.filterRange[0], this.$t('application.dateFormat')),
        this.dateFormat(this.filterRange[1], this.$t('application.dateFormat'))
      ]
    },
    updateFilterRange(filterRange) {
      this.filterRange = filterRange.map((x) =>
        this.dateFormat(x, 'YYYY-MM-DD')
      )
      this.lastFilterRange = [...this.filterRange]
      this.setDisplayRange()
    },
    saveFilter() {
      this.menu = false
      this.fetchData()
    }
  }
}
</script>
<style lang="scss" scoped>
.dashboard-wrapper {
  margin: 15px;
}
.content-label {
  color: var(--bc-title-color);
  font-family: var(--bc-font-regular);
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 1rem;
  background-color: var(--bc-bg-header);
  margin-top: 1.3rem;
  align-items: center;
  i {
    cursor: pointer;
  }
  .filter-icon {
    color: #959db5;
    margin-left: 0.5rem;
    i {
      font-size: 1rem;
    }
  }
}
.report-chart {
  padding: 1rem;
}
.expander {
  color: #959db5 !important;
  transform: rotate(45deg);
}
.default-title-layout {
  text-align: left;
  font: normal normal bold 14px/63px Aller;
  letter-spacing: 0px;
  color: #959db5;
  text-transform: uppercase;
  opacity: 1;
}
.forecast-card {
  width: 100%;
  margin-left: 0.5rem;
}
section {
  display: flex;
  margin-top: 0.5rem;
}
.forecast-wrapper {
  background: #232834;
  padding: 1rem 1rem;
}
</style>
