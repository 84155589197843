import { api } from '@cargill/shared'
import moment from 'moment'

const basePath = '/api/controltower/onTimeInFull'
const service = {}

service.calculate = async (
  startDate,
  endDate,
  target = 'monthly',
  harvestYear = null,
  fiscalYear = null
) => {
  const queryObj = {
    startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
    endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
    target: target,
    harvestYear: harvestYear ? harvestYear : '',
    fiscalYear: fiscalYear ? fiscalYear : ''
  }
  const query = Object.keys(queryObj)
    .map((key) => `${key}=${queryObj[key]}`)
    .join('&')
  return (await api.get(`${basePath}/calculate?${query}`))?.data
}

export default service
