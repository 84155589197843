const FiscalType = [
  {
    key: 'controlTower.constantes.anoFiscal',
    value: 2020
  },
  {
    key: 'controlTower.constantes.anoFiscal',
    value: 2021
  }
]

export default FiscalType
